import React, { useState, useRef, useEffect, useReducer, useCallback, Fragment } from 'react'
import { styled } from '@mui/material/styles'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Resizable } from 'react-resizable'
import type { FC } from 'react'
import { Paper, Typography,   Button, TextField, Divider, Box, Collapse, IconButton, Checkbox, FormControlLabel, Switch, useTheme, Tabs, Tab, InputAdornment, Stepper, Step, StepButton, StepLabel } from '@mui/material'
import EastIcon from '@mui/icons-material/East'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { deepCloneJson, asyncTimeout, deepEqual, dateToMs, validateUrl, processUrlInput, removeProtocolFromUrl, deleteSearchParams_sideEffect } from 'src/utils/helpers'
import useGenericContext from 'src/hooks/useGenericContext'
import ProductSelectorLarge, { ProductItemFull } from 'src/components/ProductSelectorLarge'
import { v4 as uuidv4 } from 'uuid'
import { Category } from 'src/contexts/types'
import moment from 'moment'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { PageStatus } from 'src/types/interpretor'
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers'

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { ReactComponent as NewIcon } from 'src/assets/icons/New.svg'
import { ReactComponent as LibraryOutlineIcon } from 'src/assets/icons/LibraryOutline.svg'
import { ReactComponent as LibraryFilledIcon } from 'src/assets/icons/LibraryFilled.svg'
import { ReactComponent as HooksIcon } from 'src/assets/icons/Hooks.svg'
import { ReactComponent as IntegrationsIcon } from 'src/assets/icons/Integrations.svg'

import { SocialPicker } from './components/SocialPicker'
import { LayoutPicker } from './components/LayoutPicker'
import { RedirectModal } from '../Storefronts/RedirectModal'
import { StorefrontModal } from '../../components/AffectedPagesModal/StorefrontModal'
import { handlePickersModalClick } from 'src/utils/MuiModalFix'
import { getInitialPageConfig } from 'src/utils/getInitialPageConfig'
import { PageConfig } from 'src/types/interpretor'
import { ComponentTypeResponse } from '../Components/types'
import { getComponentBase } from 'src/views/CreateComponent/componentBase'
import { ComponentsPicker } from '../CreateComponent/ComponentsPicker'
import { ComponentSelectorList } from './components/ComponentSelectorList'
import { ContainerConfig } from 'storefront-interpreter/src/api/components'
import { shouldDisplayInCustomLibraryTab, shouldDisplayInHooksTab, shouldDisplayInIntegrationsTab } from 'src/utils/classifyComponents'
import { logging } from 'src/utils/logging'
import { AffectedPagesModal } from 'src/components/AffectedPagesModal'
import { getPageStatus } from 'src/utils/GetPageStatus'
import { getRoutePath } from 'src/routes'
import { ComponentType } from 'src/types/interpretor'
import { getInitialStorePrimitiveSettings } from 'src/contexts/GenericContext'
import { navbarIconSize } from 'src/layouts/DashboardLayout/NavBar'
import {buildPreviewURL} from '../../utils/preview'

const PREFIX = 'CreateStorefront'
const classes = {
  example: `${PREFIX}-example`,
}
// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({theme}) => ({
  body: {
  },
  [`& .${classes.example}`]: {
  },
}))


const addOneMonth = (date: number) => moment(date).add(1, 'months').toDate().getTime()
const oneMinuteMs = 60 * 1000

export type UpdatePageConfigAction = {
  value: Partial<PageConfig>
}
const pageConfigReducer = (pageConfig: PageConfig, action: UpdatePageConfigAction) => {
  return {
    ...pageConfig,
    ...action.value,
  }
}

const initialCategoryConfig = getComponentBase('ALL_PRODUCTS_IN_CATEGORY', getInitialStorePrimitiveSettings())
const initialManualProductSelectionBase = getComponentBase('MANUAL_PRODUCT_SELECTION', getInitialStorePrimitiveSettings(), false)

enum StepEnum {
  Layout = 'Layout',
  Campaign = 'Campaign',
  Components = 'Components',
  Publish = 'Publish',
}

const CreateStorefront: FC = () => {
  const theme = useTheme()
  const location = useLocation()
  const history = useHistory()

  const {searchCategories, publishPageConfig, getProductsByIds, getPost, getAd, fetchStatusBasedPageConfigs, shop, getCategories, publishComponent, getAffectedPages, refetchComponents, getPageConfig, getAllDefaultPages, setUtmLinkBuilderPageId, storePrimitiveSettings } = useGenericContext()
  const allComponents = useGenericContext().components

  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [iframeSrc, setIframeSrc] = useState('')
  const nameRef = useRef<HTMLElement>(null)
  const filterStringRef = useRef<HTMLElement | null>(null)
  const internalNameRef = useRef<HTMLElement | null>(null)
  const redirectUrlRef = useRef<HTMLElement | null>(null)
  const componentInternalNameRef = useRef<HTMLElement | null>(null)

  const [readyForUpdate, setReadyForUpdate] = useState(false)
  const [readyForSubmit, setReadyForSubmit] = useState(false)

  const [publishLoading, setPublishLoading] = useState(false)
  const [editedComponentId, setEditedComponentId] = useState<string>('')

  const [categoryConfig, setCategoryConfig] = useState<ComponentTypeResponse>(initialCategoryConfig)
  useEffect(() => {
    setCategoryConfig(getComponentBase('ALL_PRODUCTS_IN_CATEGORY', storePrimitiveSettings))
  }, [storePrimitiveSettings])

  const [manualProductSelectionBase, setManualProductSelectionBase] = useState<ComponentTypeResponse>(initialManualProductSelectionBase)
  useEffect(() => {
    setManualProductSelectionBase(getComponentBase('MANUAL_PRODUCT_SELECTION', storePrimitiveSettings, false))
  }, [storePrimitiveSettings])

  const [affectedPages, setAffectedPages] = useState<PageConfig[]>([])
  const [isLoadingAffectedPages, setIsLoadingAffectedPages] = useState(false)
  const [scheduleVsPublish, setScheduleVsPublish] = React.useState(false)
  const [nextModalOpen, setNextModalOpen] = React.useState(false)
  const [libraryModalOpen, setLibraryModalOpen] = React.useState(false)
  const [iframeLoaded, setIframeLoaded] = useState(false)
  const [defaultPageConfigs, setDefaultPages] = useState<any>([])
  const [componentPickerTab, setComponentPickerTab] = useState('new_edit')

  const [steps, setSteps] = useState<StepEnum[]>([])
  const searchParams = new URLSearchParams(location.search)
  const initialStep = searchParams.get('step') || ''
  const [activeStepIndex, setActiveStepIndex] = useState(Number.parseInt(initialStep) || 0)
  const activeStep = steps[activeStepIndex]

  const [selectedProduct, setSelectedProduct] = useState<ProductItemFull | null>(null)
  const [originalPageConfig, setOriginalPageConfig] = useState<PageConfig>(getInitialPageConfig())
  const [stagedPageConfig, setStagedPageConfig] = useState<PageConfig>(getInitialPageConfig())

  const initialPageConfig = getInitialPageConfig()
  let initialLayout: PageConfig['layout'] = initialPageConfig.layout
  // if (searchParams.get('productSearch')) initialLayout = 'SINGLE_PRODUCT'
  if (searchParams.get('collectionSearch')) initialLayout = 'CATEGORY'
  initialPageConfig.layout = initialLayout
  const [pageConfig, dispatchPageConfig] = useReducer(pageConfigReducer, initialPageConfig)

  const [isProductOrCategoryChanged, setIsProductOrCategoryChanged] = useState(false)
  const [isSocialPickerChanged, setIsSocialPickerChanged] = useState(false)

  const routeParams = useParams()
  const pageConfigId: string = routeParams['id'] || ''

  const [headlineErrorString, setHeadlineErrorString] = useState('')
  const [productsErrorString, setProductsErrorString] = useState('')
  const [categoryErrorString, setCategoryErrorString] = useState('')
  const [internalNameErrorString, setInternalNameErrorString] = useState('')
  const [redirectUrlErrorString, setRedirectUrlErrorString] = useState('')
  const [componentInternalNameErrorString, setComponentInternalNameErrorString] = useState('')

  const [categories, setCategories] = useState<Category[]>([])

  const [hasDiff, setHasDiff] = useState(false)
  const [isResizing, setIsResizing] = useState(false)
  const [resizeState, setResizeState] = useState({ width: 400, height: 711 })
  const onResize = (event, { element, size, handle }) => {
    setResizeState({ width: size.width, height: size.height })
  }

  const isCreate = location.pathname.endsWith('/store/create')

  const isLocalhostIframeName = 'isLocalhostIframe_Composer'
  const [isLocalhost, setIsLocalhost] = useState(window.location.hostname === 'localhost')
  const [isLocalhostIframe, setIsLocalhostIframe] = useState(localStorage.getItem(isLocalhostIframeName) === 'true')

  const [redirectModalOpen, setRedirectModalOpen] = useState(false)

  const [readyForFinish, setReadyForFinish] = useState(false)

  const onCloseRedirectModal = () => {
    setRedirectModalOpen(false)
  }

  const onSaveRedirectModal = async (cfg: PageConfig) => {
    dispatchPageConfig({
      value: {
        redirectUrl: cfg.redirectUrl,
      },
    })
    setRedirectModalOpen(false)
  }

  const handleSetIsLocalhostIframe = ((val: boolean) => {
    setIsLocalhostIframe(val)
    localStorage.setItem(isLocalhostIframeName, val ? 'true' : '')
  })

  useEffect(() => {
    const doAsync = async () => {
      const categoriesResponse = await getCategories([])
      setCategories(categoriesResponse || [])
    }
    doAsync()
  }, [])

  useEffect(() => {
    if (pageConfig.id === '' && !isCreate) return

    let newSteps = [StepEnum.Campaign, StepEnum.Layout, StepEnum.Components, StepEnum.Publish]
    if (pageConfig.layout === 'SOCIAL_HOME')
      newSteps = newSteps.filter(s => s !== StepEnum.Layout)

    setSteps(newSteps)
  }, [setSteps, pageConfig.layout, isCreate, pageConfig.id])

  useEffect(() => {
    if (categoryConfig.contentConfiguration.type !== 'CATEGORY_PRIMITIVE') return
    // TODO: I assume this breaks because of enums not exporting like types?
    //   need Yusuf to look into this as well, casting here to any to get around the CI build error
    const workaroundEnumImport = {
      ...categoryConfig.contentConfiguration.value,
      containerType: categoryConfig.containerType,
    } as any

    dispatchPageConfig({value: {
      categories: workaroundEnumImport,
    }})
  }, [categoryConfig])

  useEffect(() => {
    // TODO: make a helper function that compares the objects
    let newHasDiff = false

    newHasDiff = ['name', 'subheadline', 'layout'].reduce(
      (acc, el) => acc || (originalPageConfig[el] !== pageConfig[el])
      , false)

    newHasDiff = newHasDiff ||
      originalPageConfig.startDate !== pageConfig.startDate ||
      originalPageConfig.endDate !== pageConfig.endDate

    newHasDiff = newHasDiff ||
      !deepEqual(originalPageConfig.products, pageConfig.products, ['objId'])

    newHasDiff = newHasDiff ||
      !deepEqual(originalPageConfig.campaignMedias, pageConfig.campaignMedias, ['objId'])

    newHasDiff = newHasDiff ||
      !deepEqual(originalPageConfig.componentIds, pageConfig.componentIds, ['objId'])

    newHasDiff = newHasDiff ||
      !deepEqual(originalPageConfig.components, pageConfig.components, ['objId'])

    setHasDiff(newHasDiff)
  }, [originalPageConfig, pageConfig])


  useEffect(() => {
    if (nameRef.current && !pageConfigId) {
      nameRef.current.focus()
    }
  }, [pageConfigId, nameRef])


  useEffect(() => {
    if (!pageConfigId) return

    const doAsync = async () => {
      const cfg = await getPageConfig(pageConfigId)
      if(!cfg) return

      // TODO: what is this for
      setCategoryConfig((oldCategoryConfig) => {
        if (cfg?.categories?.apiSettings) oldCategoryConfig.contentConfiguration.value.apiSettings = cfg.categories.apiSettings
        if (cfg?.categories?.uiSettings) oldCategoryConfig.contentConfiguration.value.uiSettings = cfg.categories.uiSettings
        return {...oldCategoryConfig}
      })

      // NOTE: storing each as a deep cloned object
      // NOTE: keeping original reference for diff comparision
      setOriginalPageConfig(deepCloneJson(cfg))
      setStagedPageConfig(deepCloneJson(cfg))
      dispatchPageConfig({value: deepCloneJson(cfg)})
    }
    doAsync()
  }, [getAd, getPageConfig, getPost, pageConfigId])

  useEffect(() => {
    getAllDefaultPages()
      .then(result => result?.data || [])
      .then(newDefaultPageConfigs => {
        setDefaultPages(newDefaultPageConfigs)
        if (!window.location.pathname.endsWith('/store/create')) return

        const cfg = deepCloneJson(pageConfig)
        const defaultPageConfig = newDefaultPageConfigs.find(x => x.layout === cfg.layout)
        if (defaultPageConfig) {
          cfg.components = defaultPageConfig.components
          cfg.componentIds = defaultPageConfig.components.map(x => x.componentId)
          cfg.mlOrderingUsed = defaultPageConfig.mlOrderingUsed
        }
        else {
          logging({error: 'default page not found', layout: cfg.layout})
        }
        setOriginalPageConfig(deepCloneJson(cfg))
        setStagedPageConfig(deepCloneJson(cfg))
        dispatchPageConfig({value: deepCloneJson(cfg)})
      })
  }, [getAllDefaultPages])

  useEffect(() => {
    if (!pageConfig) return
    // NOTE: setProductItems is called only once, but still check here not to reset user selection
    if (selectedProduct) return

    const fetchSelectedProduct = async () => {
      const product = await getProductsByIds([pageConfig.products[0]?.productId || ''])
      product && setSelectedProduct(product[0])
    }
    fetchSelectedProduct()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProductsByIds, selectedProduct, pageConfig.products[0]?.productId])

  useEffect(() => {
    if (!iframeLoaded) return

    const timeout = setTimeout(() => {
      const iframeWindow = iframeRef.current?.contentWindow
      if (!iframeWindow) return

      iframeWindow.postMessage(JSON.stringify({type: 'setForceRenderUnpublished', value: true}), '*')
      iframeWindow.postMessage(JSON.stringify({type: 'setPageConfig', value: pageConfig}), '*')
    }, 200)
    return () => clearTimeout(timeout)
  },
  [pageConfig, iframeLoaded, iframeRef])

  useEffect(() => {
    if (!selectedProduct) return
    dispatchPageConfig({ value: { products: [{ objId: selectedProduct.objId, productId: selectedProduct.productId }] } })
  }, [selectedProduct])

  const editedComponentConfig = pageConfig.components.find(c => c.componentId === editedComponentId) || manualProductSelectionBase

  const validateComponentInternalName = (): boolean => {
    if (!editedComponentConfig.internalName.trim()) {
      setComponentInternalNameErrorString('Please Enter an Internal Name')
      componentInternalNameRef.current?.focus()
      return false
    }
    return true
  }

  useEffect(() => {
    if (componentInternalNameErrorString && editedComponentConfig.internalName)
      setComponentInternalNameErrorString('')
  }, [componentInternalNameErrorString, editedComponentConfig.internalName])

  useEffect(() => {
    if (internalNameErrorString && pageConfig.internalName)
      setInternalNameErrorString('')
  }, [internalNameErrorString, pageConfig.internalName])

  useEffect(() => {
    if (!pageConfig.endDate || (redirectUrlErrorString && pageConfig.redirectUrl))
      if (validateUrl(pageConfig.redirectUrl))
        setRedirectUrlErrorString('')
  }, [redirectUrlErrorString, pageConfig.redirectUrl, pageConfig.endDate])

  useEffect(() => {
    if (productsErrorString && pageConfig.products.length > 0)
      setProductsErrorString('')
  }, [productsErrorString, pageConfig.products.length])

  useEffect(() => {
    if (categoryErrorString && pageConfig?.categories && pageConfig?.categories?.apiSettings?.categoryIds?.length > 0)
      setCategoryErrorString('')
  }, [categoryErrorString, pageConfig?.categories, pageConfig?.categories?.apiSettings?.categoryIds?.length])

  const isProductValid = () => {
    if (pageConfig.layout !== 'SINGLE_PRODUCT') return true
    if (pageConfig.products.length) return true
  }

  const isCategoryValid = () => {
    if (pageConfig.layout !== 'CATEGORY') return true
    if (pageConfig?.categories?.apiSettings.categoryIds.length) return true
  }

  const isHeadlineValid = () => {
    if (pageConfig.layout === 'SOCIAL_HOME') return true
    if (pageConfig.headline.trim()) return true
  }

  const isInternalNameValid = () => {
    if (pageConfig.internalName.trim()) return true
  }

  const isRedirectUrlValid = () => {
    if (pageConfig.endDate === undefined) return true
    if (validateUrl(pageConfig.redirectUrl)) return true
  }

  const handleValidate = () => {
    const errorSteps: StepEnum[] = []

    if (!isProductValid()) {
      setProductsErrorString('Please select a product')
      errorSteps.push(StepEnum.Layout)
    }

    if (!isCategoryValid()) {
      setCategoryErrorString('Please select a category')
      errorSteps.push(StepEnum.Layout)
    }

    if (!isHeadlineValid()) {
      errorSteps.push(StepEnum.Campaign)
      setHeadlineErrorString('Please enter a headline')
    }

    if (!isInternalNameValid()) {
      errorSteps.push(StepEnum.Publish)
      setInternalNameErrorString('Please enter an internal name')
    }

    if (!isRedirectUrlValid()) {
      errorSteps.push(StepEnum.Publish)
      setRedirectUrlErrorString('Please input a valid URL when end date is set')
    }

    if (errorSteps.length)
      setActiveStepIndex(steps.indexOf(errorSteps[0]))
    else return true
  }

  const handleSubmit = useCallback(async () => {
    const hasErrors = !handleValidate()
    if (hasErrors) {
      setPublishLoading(false)
      return
    }
    try {
      const response = await publishPageConfig(pageConfig)

      if (response?.status === 200) {
        console.log('Store updated sucessfully')

        const updatedPageConfig = response.data
        if (!updatedPageConfig) return

        setOriginalPageConfig(deepCloneJson(updatedPageConfig))
        setStagedPageConfig(deepCloneJson(updatedPageConfig))
        dispatchPageConfig({value: deepCloneJson(updatedPageConfig)})
        setReadyForFinish(true)
      }
      else {
        window.alert('Error updating store')
      }
    }
    catch (error) {
      console.log(error)
      window.alert('Error updating store')
    }
  }, [pageConfig, publishPageConfig])

  useEffect(() => {
    if (!readyForFinish) return

    const callback= async () => {
      const newStatus = stagedPageConfig.status as PageStatus
      await fetchStatusBasedPageConfigs(newStatus)
      setUtmLinkBuilderPageId(stagedPageConfig.id)
      history.push(getRoutePath('stores'))
    }
    callback()
  }, [readyForFinish, stagedPageConfig, fetchStatusBasedPageConfigs, setUtmLinkBuilderPageId, history])

  useEffect(() => {
    const fetchIframeSrc = async () => {
      const src = await buildPreviewURL(shop, '', `id=${pageConfigId || ''}`, isLocalhost && isLocalhostIframe)
      setIframeSrc(src)
    }

    void fetchIframeSrc()
  }, [shop, isLocalhost, isLocalhostIframe, pageConfigId])

  const handleSetEditedComponentConfig = (item: ContainerConfig, skipSetEdit?: boolean, discardPrevious?: boolean) => {
    const newPageConfig = discardPrevious ? deepCloneJson(stagedPageConfig) : pageConfig

    let newComponents = newPageConfig.components
    const overrides = newPageConfig.pageComponentOverrides || {}

    // detach from library
    const libraryComponent = allComponents.find(c => c.componentId === item.componentId)
    if (libraryComponent) {
      if (!item.visibleInLibrary) {
        item = deepCloneJson(item)
        item.componentId = uuidv4()

        overrides[item.componentId] = {
          ...deepCloneJson(overrides[libraryComponent.componentId] || null),
        }
        delete overrides[libraryComponent.componentId]
      }
      else {
        const alreadyAdded = newComponents.find(c => c.componentId === item.componentId)
        if (!alreadyAdded) newComponents = [...newComponents, deepCloneJson(item)]
      }
      newComponents = newComponents.map(c => c.componentId === libraryComponent.componentId ? item : c)
    }
    else {
      // switching components to edit, reset the previous one to original value
      const isSwitchingComponents = editedComponentId && (item.componentId !== editedComponentId)
      if (isSwitchingComponents) {
        const originalComponent = deepCloneJson(stagedPageConfig.components.find(c => c.componentId === editedComponentId) || null)
        if (originalComponent) newComponents = newComponents.map(c => c.componentId === editedComponentId ? originalComponent : c)
      }
      const existingComponent = newComponents.find(c => c.componentId === item.componentId)
      if (!existingComponent) newComponents = [...newComponents, item]

      newComponents = newComponents.map(c => c.componentId === item.componentId ? item : c)
    }

    const newMlOrderingUsed = [...newPageConfig.mlOrderingUsed]
    const componentIndex = newComponents.findIndex(c => c.componentId === item.componentId)
    newMlOrderingUsed[componentIndex] = newMlOrderingUsed[componentIndex] === undefined ? true : newMlOrderingUsed[componentIndex]

    dispatchPageConfig({value: {
      components: newComponents,
      componentIds: newComponents.map(c => c.componentId),
      pageComponentOverrides: overrides,
      mlOrderingUsed: newMlOrderingUsed,
    }})

    if (item.componentId !== editedComponentId) {
      if (!skipSetEdit) setEditedComponentId(item.componentId)
    }
  }

  const handleDiscardComponentChanges = (): boolean => {
    if (!editedComponentId) return true

    const stagedComponentConfig = stagedPageConfig.components.find((c) => c.componentId === editedComponentId)
    const editedHasDiff = !deepEqual(stagedComponentConfig, editedComponentConfig)

    let shouldDiscard = true
    if (editedHasDiff) shouldDiscard = window.confirm('Discard component changes?')

    if (shouldDiscard) discardChanges()
    return shouldDiscard
  }

  const discardChanges = (): void => {
    const staged = deepCloneJson(stagedPageConfig)
    dispatchPageConfig({value: {
      components: staged.components,
      componentIds: staged.componentIds,
      pageComponentOverrides: staged.pageComponentOverrides,
    }})
    setComponentInternalNameErrorString('')
    setEditedComponentId('')
  }

  const handleOnEditClick = (item: ContainerConfig) => {
    setComponentPickerTab('new_edit')

    // if edit in progress and another component is requested for edit
    if (editedComponentId && item.componentId !== editedComponentId) {
      const didDiscard = handleDiscardComponentChanges()
      if (!didDiscard) return
    }
    handleSetEditedComponentConfig(item, false, true)
  }

  const handleOnAddClick = (item: ContainerConfig) => {
    const newItem = deepCloneJson(item)

    if (newItem.componentType === 'ALL_PUBLISHED_CAMPAIGNS' && newItem.contentConfiguration.type === 'STOREFRONT_PAGE_PRIMITIVE' && !newItem.contentConfiguration.value.uiSettings.imageFit && storePrimitiveSettings) {
      newItem.contentConfiguration.value.uiSettings.imageFit = storePrimitiveSettings.storefront.uiSettings.imageFit
    }

    const newComponents = [...(pageConfig.components), newItem]
    const newComponentIds = newComponents.map(c => c.componentId)
    const newMlOrderingUsed = [...pageConfig.mlOrderingUsed, true]

    dispatchPageConfig({value: {
      components: newComponents,
      componentIds: newComponentIds,
      mlOrderingUsed: newMlOrderingUsed,
    }})

    setStagedPageConfig(deepCloneJson({
      ...pageConfig,
      components: newComponents,
      componentIds: newComponentIds,
      mlOrderingUsed: newMlOrderingUsed,
    }))
  }

  const handleScheduleVsPublish = () => {
    const publishNow = !scheduleVsPublish
    setScheduleVsPublish(publishNow)
    if (publishNow) {
      // publishing NOW
      setRedirectUrlErrorString('')
      dispatchPageConfig({ value: {
        startDate: new Date().getTime(),
        endDate: undefined,
      } })
    }
  }

  const validateDateRange = (startDate: number | undefined, endDate: number | undefined) => {
    if (startDate === undefined || endDate === undefined) return true
    return startDate < endDate
  }

  const isDateRangeValid = validateDateRange(pageConfig.startDate, pageConfig.endDate)
  const canSubmit = isDateRangeValid && !internalNameErrorString && !redirectUrlErrorString

  // Split view integration
  const clientYPosition = useRef<null | number>(null)
  const componentSelectorOriginalHeight = useRef<number>(0)
  const [defaultComponentSelectorHeight, setDefaultComponentSelectorHeight] = useState<number>(255)
  const [componentSelectorHeight, setComponentSelectorHeight] = useState(defaultComponentSelectorHeight)
  const componentSelectorRef = useRef<HTMLElement | null>(null)
  const moveRef = useRef<boolean>(false)

  const [descriptionMaxRows, setDescriptionMaxRows] = React.useState(1)

  const onMouseDown = (e) => {
    clientYPosition.current = e.clientY || e.touches[0].clientY
    componentSelectorOriginalHeight.current = componentSelectorRef?.current?.clientHeight || 0
  }

  const resizeComponentSelector = (() => {
    if(moveRef.current) return
    if(componentSelectorHeight > 0) {
      setDefaultComponentSelectorHeight(componentSelectorRef?.current?.clientHeight || 0)
      setComponentSelectorHeight(0)
    } else {
      setComponentSelectorHeight(defaultComponentSelectorHeight)
    }
  })

  const onMouseUp = () => {
    clientYPosition.current = null
    setTimeout(() => {
      moveRef.current = false
    })
  }

  const onMouseMove = (e) => {
    if(clientYPosition.current) {
      moveRef.current = true
      const clientY = (e.clientY || e.touches[0].clientY)
      const newHeight = clientYPosition.current - clientY + (componentSelectorOriginalHeight.current || 0)
      if (newHeight <= 0) {
        setComponentSelectorHeight(0)
      } else {
        setComponentSelectorHeight(newHeight)
      }
    }
  }

  useEffect(() => {
    if (!readyForSubmit) return
    setReadyForSubmit(false)
    handleSubmit()
  }, [readyForSubmit, handleSubmit])

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)
    document.addEventListener('touchmove', onMouseMove)
    document.addEventListener('touchend', onMouseUp)

    return () => {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
      document.removeEventListener('touchmove', onMouseMove)
      document.removeEventListener('touchend', onMouseUp)
    }
  }, [componentSelectorHeight])

  const handleUpdateEditedComponent = useCallback((editedComponent?: ContainerConfig) => {
    // NOTE: this is the case when skipSetEdit is used
    if (!editedComponent) editedComponent = pageConfig.components.find(x => x.componentId === editedComponentId)
    if (!editedComponent) {
      return
    }

    // immediately update global components
    const globalComponent = allComponents.find(x => x.componentId === editedComponentId)
    if (globalComponent) {
      // NOTE: if we want to immediately create new global components do it here, but we currently want to wait for store publish
      publishComponent(editedComponent, editedComponent.componentId).then(x => {
        refetchComponents()
      })
    }
    else {
    }

    setStagedPageConfig(deepCloneJson({
      ...stagedPageConfig,
      components: pageConfig.components,
      componentIds: pageConfig.components.map(c => c.componentId),
    }))
    setEditedComponentId('')
    setLibraryModalOpen(false)
  }, [
    editedComponentId, allComponents, stagedPageConfig, pageConfig.components, pageConfig.componentIds, publishComponent, refetchComponents,
  ])

  useEffect(() => {
    if (!readyForUpdate) return
    setReadyForUpdate(false)
    // NOTE: refactor this soon
    const componentConfigs = pageConfig.components.filter(x => x['isForUpdate'])
    componentConfigs.forEach(x => {
      delete x['isForUpdate']
      handleUpdateEditedComponent(x)
    })
    if (!componentConfigs.length) {
      handleUpdateEditedComponent(editedComponentConfig)
    }
  }, [pageConfig.components, readyForUpdate, handleUpdateEditedComponent, editedComponentConfig])

  const stagedComponent = stagedPageConfig.components.find(c => c.componentId === editedComponentId)

  const editActionsRow = <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
    }}
  >
    <Box
      sx={{
        display: 'flex',
      }}
    >
    </Box>
    {/*
    <Box
      sx={{
      }}
    >
      {!editedComponentId &&
      <Button
        sx={{
          borderColor:`${theme.palette.brand.urIndigo} !important`,
          color: `${theme.palette.brand.urIndigo} !important`,
        }}
        onClick={() => {
          // NOTE: changes to any field will switch to new component edit
          if (!stagedComponent) componentInternalNameRef.current?.focus()
        }}
        variant='outlined'
      >
        + Add
      </Button>
      }
    </Box> */}

  </Box>

  const handleHeadlineUpdate = (headline: string) => {
    dispatchPageConfig({
      value: {
        headline,
      },
    })
  }

  const handleSubheadlineUpdate = (subheadline: string) => {
    dispatchPageConfig({
      value: {
        subheadline,
      },
    })
  }

  const handleCampaignMediasUpdate = (campaignMedias: PageConfig['campaignMedias']) => {
    dispatchPageConfig({
      value: {
        campaignMedias,
      },
    })
  }

  const layoutSettings = <LayoutPicker
    layout={pageConfig.layout}
    onChange={(newLayout) => {
      const layoutComponents = defaultPageConfigs.find(x => x.layout === newLayout)?.components || []
      dispatchPageConfig({
        value: {
          components: layoutComponents,
          componentIds: layoutComponents.map(x => x.componentId),
          layout: newLayout as any,
        },
      })
    }}
  />

  const productSettings = <Box>
    <Box sx={{py: 1, fontWeight: 'bold'}}>Product</Box>
    <ProductSelectorLarge
      initialProductSearch={isProductOrCategoryChanged ? undefined : searchParams.get('productSearch') || undefined}
      openWhenNotSelected
      onSelect={(item) => {
        setSelectedProduct(item)
        if (!isProductOrCategoryChanged) {
          setIsProductOrCategoryChanged(true)
        }
      }}
      selected={selectedProduct}
      inputRef={filterStringRef}
      error={!!productsErrorString}
      label={productsErrorString || 'Select Product'}
    />
  </Box>

  // NOTE: this is done differently than products/ads/posts becuse the implementation of category is completely different
  useEffect(() => {
    const initialCategorySearch = searchParams.get('collectionSearch')
    if (!initialCategorySearch) return
    searchCategories(initialCategorySearch, 1, 0, [])
      .then(response => {
        const initialCategory = response?.[0]
        if (initialCategory) {
          const newComponent: ComponentTypeResponse = {...categoryConfig}
          if (newComponent.contentConfiguration.type !== 'CATEGORY_PRIMITIVE') return
          newComponent.contentConfiguration.value.apiSettings.categoryIds = [initialCategory.id]
          setCategoryConfig(newComponent)
          // isProductOrCategoryChanged(true) // no need to set as we are at topmost level
        }
      })
  }, [])

  const categorySettings = <Box>
    <Box sx={{py: 1, fontWeight: 'bold'}}>Category</Box>
    <ComponentsPicker
      layout={pageConfig.layout}
      hideTitleAndType
      componentConfig={categoryConfig}
      setComponentConfig={setCategoryConfig}
      defaultVisibleInLibrary={false}
      error={!!categoryErrorString}
      label={categoryErrorString || 'Select Category'}
    />
  </Box>

  const campaignSettings = <SocialPicker
    // TODO: update to react-router-dom 6 then use useSearchParams that can alter url with proper state updates
    initialAdId={isSocialPickerChanged ? undefined : searchParams.get('adId') || undefined}
    initialPostId={isSocialPickerChanged ? undefined : searchParams.get('postId') || undefined}
    initialIntegrationIndex={isSocialPickerChanged ? undefined : Number.parseInt(searchParams.get('integrationIndex') || '') || undefined}
    onPostOrAdSelected={() => {
      if (!isSocialPickerChanged) {
        setIsSocialPickerChanged(true)
        const hasInitialAdOrPost = searchParams.get('adId') || searchParams.get('postId')
        if (hasInitialAdOrPost)
          setActiveStepIndex(activeStepIndex + 1)
      }
    }}

    headline={pageConfig.headline}
    onHeadlineChange={handleHeadlineUpdate}
    subheadline={pageConfig.subheadline}
    onSubheadlineChange={handleSubheadlineUpdate}
    campaignMedias={pageConfig.campaignMedias}
    onCampaignMediasChange={handleCampaignMediasUpdate}
    headlineErrorString={headlineErrorString}
    setheadlineErrorString={setHeadlineErrorString}
  />

  const componentsSettings = <Box sx={{mx: -1}}>
    <ComponentSelectorList
      editedId={editedComponentId || ''}
      allItems={[] as any}
      items={pageConfig.components}
      onRemoveClick={(item) => {
        const newComponents = pageConfig.components.filter(c => c.componentId !== item.componentId)
        const newComponentIds = newComponents.map(c => c.componentId)
        const newMlOrderingUsed = pageConfig.mlOrderingUsed.filter((x, index) => index !== pageConfig.componentIds.findIndex(id => id === item.componentId))

        dispatchPageConfig({value: {
          components: newComponents,
          componentIds: newComponentIds,
          mlOrderingUsed: newMlOrderingUsed,
        }})

        const editedComponent = newComponents.find(c => c.componentId === editedComponentId)
        let stagedComponents = newComponents
        if (editedComponent) {
          // keep the unchanged state in stage (unedited or non added)
          if (stagedComponent) stagedComponents = newComponents.map(c => c.componentId === editedComponentId ? stagedComponent : c)
          else stagedComponents = newComponents.filter(c => c.componentId !== editedComponentId)
        }

        // stop editing if the component is removed
        if (editedComponentId === item.componentId) setEditedComponentId('')

        setStagedPageConfig(deepCloneJson({
          ...stagedPageConfig,
          components: stagedComponents,
          componentIds: stagedComponents.map(c => c.componentId),
          mlOrderingUsed: newMlOrderingUsed,
        }))
      }}

      onLibraryClick={(item) => {
        // if edit in progress and another component is requested for edit
        if (editedComponentId && item.componentId !== editedComponentId) {
          const didDiscard = handleDiscardComponentChanges()
          if (!didDiscard) return
        }
        item.visibleInLibrary = !item.visibleInLibrary
        item['isForUpdate'] = true
        handleSetEditedComponentConfig(item, true)
        setReadyForUpdate(true)
      }}
      onEditClick={handleOnEditClick}
      onSort={(items) => {
        // Attach the mlordering values to components
        const newMlOrderingAttachedToComponent: Record<string, boolean> = {}
        pageConfig.mlOrderingUsed.forEach((value, index) => {
          newMlOrderingAttachedToComponent[pageConfig.componentIds[index]] = value
        })

        const newComponents = items

        // Extract the order values
        const newMlOrdering =  newComponents.map(item => newMlOrderingAttachedToComponent[item.componentId])

        dispatchPageConfig({value: {
          components: newComponents,
          componentIds: newComponents.map(c => c.componentId),
          mlOrderingUsed: newMlOrdering,
        }})

        // NOTE: ignore edited component
        const editedComponent = newComponents.find(c => c.componentId === editedComponentId)
        let stagedComponents = newComponents
        if (editedComponent) {
          // keep the unchanged state in stage (unedited or non added)
          if (stagedComponent) stagedComponents = newComponents.map(c => c.componentId === editedComponentId ? stagedComponent : c)
          else stagedComponents = newComponents.filter(c => c.componentId !== editedComponentId)
        }
        setStagedPageConfig(deepCloneJson({
          ...stagedPageConfig,
          components: stagedComponents,
          componentIds: stagedComponents.map(c => c.componentId),
          mlOrderingUsed: newMlOrdering,
        }))
      }}

      getTitle={(item) => pageConfig.pageComponentOverrides?.[item.componentId]?.title || item.title || ''}
      onTitleChange={(item, val) => {
        const globalComponent = allComponents.find(x => x.componentId === item.componentId)

        // global ? write into overrides, keep title intact
        // no global ? clear overrides, write into title

        // set title
        const newComponents = pageConfig.components.map(c => {
          if (c.componentId === item.componentId)
            c.title = globalComponent ? c.title : val
          return c
        })

        const newOverrides = {
          ...pageConfig.pageComponentOverrides,
          [item.componentId]: {
            ...pageConfig.pageComponentOverrides?.[item.componentId],
            title: globalComponent ? val : undefined,
          },
        }

        dispatchPageConfig({value: {
          components: newComponents,
          componentIds: newComponents.map(c => c.componentId),
          pageComponentOverrides: newOverrides,
        }})

        setStagedPageConfig(deepCloneJson({
          ...stagedPageConfig,
          components: newComponents,
          componentIds: newComponents.map(c => c.componentId),
          pageComponentOverrides: newOverrides,
        }))
      }}
      onAiOrderingClick={(e, item) => {

        const newMlOrdering = [...stagedPageConfig.mlOrderingUsed]
        newMlOrdering[stagedPageConfig.componentIds.findIndex(x => x === item.componentId)] = e.target.checked || false

        dispatchPageConfig({value: {
          mlOrderingUsed: newMlOrdering,
        }})

        setStagedPageConfig(deepCloneJson({
          ...pageConfig,
          mlOrderingUsed: newMlOrdering,
        }))
      }}
      isAiOrderingChecked={(componentId: string) => stagedPageConfig.mlOrderingUsed[stagedPageConfig.componentIds.findIndex(x => x === componentId)]}
    />
  </Box>

  const publishSettings = <Box
    onClick={handlePickersModalClick}
  >
    <Box
      sx={{
        pt: 2,
        fontWeight: 'bold',
        marginBottom: '12px',
      }}
    >
      {pageConfigId ? 'UPDATE' : 'PUBLISH'} CAMPAIGN STORE
    </Box>

    <TextField
      style={{width: '100%'}}
      label='Internal Name' /* MODAL */
      inputRef={internalNameRef}
      error={!!internalNameErrorString}
      helperText={internalNameErrorString}
      margin='dense' size='small' variant='outlined'
      required
      value={pageConfig.internalName}
      onChange={(e) => {
        dispatchPageConfig({
          value: {
            internalName: e.target.value,
          },
        })
      }}
    />

    <Box
      sx={{
        display: 'flex',
        userSelect: 'none',
        pb: 1,
      }}
    >
      <Box
        sx={{
          py: 1,
          display: 'flex',
          gap: 1,
        }}
        onClick={handleScheduleVsPublish}
      >
        <FormControlLabel
          label={<Typography style={{
            marginBottom: '4px',
            marginLeft: '-16px',
            fontWeight: !scheduleVsPublish ? 'bold' : 'inherit',
            color: !scheduleVsPublish ? theme.palette.brand.urIndigo : '',
          }}>
            Schedule
          </Typography>}
          labelPlacement='start'
          control={
            <Switch
              style={{color: theme.palette.brand.urIndigo}}
              checked={scheduleVsPublish}
              // onChange={handleScheduleVsPublish}
            />
          }
        />
        <Box
          style={{
            fontWeight: scheduleVsPublish ? 'bold' : 'inherit',
            alignSelf: 'center',
            color: scheduleVsPublish ? theme.palette.brand.urIndigo : '',
          }}
          // onClick={handleScheduleVsPublish}
        >
          Publish now
        </Box>
      </Box>
    </Box>

    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box>
        <Box sx={{
          display: 'flex',
          gap: 1,
        }}>
          <DatePicker
            sx={{flex: 1}}
            label='Start date'
            disabled={scheduleVsPublish}
            maxDate={pageConfig.endDate}
            format="yyyy-MM-dd"
            onChange={(v) => {
              if (!v) return
              const startDate = dateToMs(v)

              let endDate = pageConfig.endDate
              if (endDate !== undefined)
                if (startDate > endDate) endDate = addOneMonth(startDate)

              dispatchPageConfig({ value: {
                startDate: startDate,
                endDate: endDate,
              }})
            }}
            value={pageConfig.startDate}
          />

          <TimePicker
            sx={{flex: 1}}
            label='Start time'
            disabled={scheduleVsPublish}
            maxTime={pageConfig.endDate ? pageConfig.endDate - oneMinuteMs : undefined}
            disableIgnoringDatePartForTimeValidation
            onChange={(v) => {
              if (!v) return
              const startDate = dateToMs(v)
              dispatchPageConfig({ value: { startDate: startDate} })
            }}
            value={pageConfig.startDate}
          />
        </Box>

        <FormControlLabel
          label='Set end date'
          disabled={scheduleVsPublish}
          control={
            <Checkbox
              checked={pageConfig.endDate !== undefined}
              onChange={(e) => {
                const val = e.target.checked
                const payload = {endDate: undefined} as any
                const now = new Date().getTime()
                if (val) payload.endDate = addOneMonth(now)
                else setRedirectUrlErrorString('')
                dispatchPageConfig({ value: payload })
              }}
            />
          }
        />

        <Box sx={{
          display: 'flex',
          gap: 1,
        }}>
          <DatePicker
            sx={{flex: 1}}
            label='End date'
            disabled={scheduleVsPublish || pageConfig.endDate === undefined}
            format="yyyy-MM-dd"
            minDate={pageConfig.endDate !== undefined ? pageConfig.startDate : undefined}
            onChange={(v) => {
              if (!v) return
              const endDate = new Date(v).getTime()
              dispatchPageConfig({ value: { endDate: endDate} })
            }}
            value={pageConfig.endDate}
          />

          <TimePicker
            sx={{flex: 1}}
            label='End time'
            disabled={scheduleVsPublish || pageConfig.endDate === undefined}
            minTime={pageConfig.endDate !== undefined ?
              (pageConfig.startDate !== undefined ? // if endDate defined then use startDate here
                (pageConfig.startDate + oneMinuteMs) : undefined) // to show error when startDate === endDate
              : undefined
            }
            disableIgnoringDatePartForTimeValidation
            onChange={(v) => {
              if (!v) return
              const endDate = dateToMs(v)
              dispatchPageConfig({ value: { endDate: endDate} })
            }}
            value={pageConfig.endDate}
          />
        </Box>
      </Box>
    </LocalizationProvider>

    <Box
      sx={{
        display: 'flex',
        pt: 2,
      }}
    >
      <TextField
        disabled={scheduleVsPublish || pageConfig.endDate === undefined}
        style={{width: '100%'}}
        label='Redirect URL at campaign end'
        inputRef={redirectUrlRef}
        error={!!redirectUrlErrorString}
        required={!!pageConfig.endDate}
        helperText={redirectUrlErrorString}
        margin='dense' variant='outlined' size='small'
        InputProps={{
          startAdornment: <InputAdornment sx={{pr: 0, mr: 0, userSelect: 'none'}} position="start">
            <Box sx={{color: (scheduleVsPublish || pageConfig.endDate === undefined) ? '#E9ECEF': ''}}>https://</Box>
          </InputAdornment>,
        }}
        value={removeProtocolFromUrl(pageConfig.redirectUrl)}
        onChange={(e) => {
          setRedirectUrlErrorString('')
          dispatchPageConfig({ value: { redirectUrl: processUrlInput(e.target.value)} })
        }}
      />
    </Box>

    <Box
      sx={{
        marginTop: '6px',
        color: 'red',
      }}
    >
      {!isDateRangeValid &&
          <Box>End date must happen after the start date.</Box>
      }
    </Box>
    <Button
      disabled={!canSubmit || publishLoading}
      style={{
        marginTop: '12px',
        width: '100%',
      }}
      variant='contained' color='secondary'
      onClick={async () => {

        if (stagedComponent) {
          const isValid = validateComponentInternalName()
          if (!isValid) return
        }

        if (!handleDiscardComponentChanges()) return

        const hasErrors = !handleValidate()
        if (hasErrors) return

        if (publishLoading) return
        setPublishLoading(true)
        const createdComponents: ContainerConfig[] = []
        const updatedComponents: ContainerConfig[] = []

        pageConfig.components.forEach(c => {
          if (originalPageConfig.components.find(oc => oc.componentId === c.componentId))
            updatedComponents.push(c)
          else {
            const globalComponent = allComponents.find(x => x.componentId === c.componentId)
            // global components should not be created or updated here, they've already been updated
            if (!globalComponent) createdComponents.push(c)
          }
        })

        // store old ids before publishing
        const dummyIds = createdComponents.map(c => c.componentId)

        await Promise.all([
          Promise.all(createdComponents.map(c => publishComponent(c)))
            .then(responses => {
              createdComponents.forEach((c, i) => {
                const response = responses?.[i]?.data
                if (!response?.componentId) {
                  const err = new Error('creation of component returned an empty id')
                  console.log(err, c, response)
                  logging(err, { tags: { section: 'create component componentId missing'} })
                  throw err
                }
                // NOTE: mutate componentId to the one returned by the server
                c.componentId = response.componentId
              })
            }),
          Promise.all(updatedComponents.map(c => publishComponent(c, c.componentId)))
            .then(responses => {
              updatedComponents.forEach((c, i) => {
              })
            }),
        ])
        refetchComponents()

        const newComponents = [...(pageConfig.components)] // ids mutated above
        const newComponentIds = pageConfig.components.map(c => c.componentId)
        const newMlOrderingUsed = pageConfig.componentIds.map((c, i) => pageConfig.mlOrderingUsed[i] || false)
        const newOverrides = pageConfig.pageComponentOverrides || {}

        // transplant overrides from dummy ids to new ids
        dummyIds.forEach((dummyId, i) => {
          const newId = createdComponents[i].componentId
          if (newOverrides[dummyId]) {
            newOverrides[newId] = newOverrides[dummyId]
            delete newOverrides[dummyId]
          }
        })
        // cleanup removed component ids from overrides
        Object.keys(newOverrides).forEach(id => {
          if (newOverrides[id] && !newComponentIds.includes(id))
            delete pageConfig.pageComponentOverrides?.[id]
        })

        dispatchPageConfig({ value: {
          components: newComponents,
          componentIds: newComponentIds,
          mlOrderingUsed: newMlOrderingUsed,
          pageComponentOverrides: newOverrides,
        }})

        setReadyForSubmit(true)
      }}
    >
      {publishLoading ? (pageConfigId ? 'Updating...' : 'Creating...') : (pageConfigId ? 'Update' : 'Create') + ' Store & Go to Link Builder'}
    </Button>
  </Box>

  const isStepCompleted = (step: StepEnum) => {
    if (step === StepEnum.Layout) return isProductValid() && isCategoryValid()
    else if (step === StepEnum.Campaign) return isHeadlineValid()
    else if (step === StepEnum.Components) return true
    else if (step === StepEnum.Publish) return isInternalNameValid() && isRedirectUrlValid()
  }

  const isStepError = (step: StepEnum) => {
    if (step === StepEnum.Layout && (productsErrorString || categoryErrorString)) return true
    else if (step === StepEnum.Campaign && headlineErrorString) return true
    else if (step === StepEnum.Publish && (internalNameErrorString || redirectUrlErrorString)) return true
  }

  return (
    <Root>
      <RedirectModal isOpen={redirectModalOpen} onSave={onSaveRedirectModal} onClose={onCloseRedirectModal} pageConfig={pageConfig}/>

      <Box style={{
        display: 'flex',
        height: `calc(100vh)`,
        alignItems: 'stretch',
        justifyContent: 'space-between',
      }}>
        <Paper sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}>
          {/* create campaign store */}
          <Box
            sx={{
              height: navbarIconSize + 1, // TODO: why does it need this extra pixel to match sidebar
              display: 'flex',
              flexWrap: 'wrap',
              alignContent: 'center',
              justifyContent: 'space-between',
              borderBottom: '1px solid #E5E5E5',
              px: 1,
              py: 2,
            }}
          >
            <Typography variant='h5'
              sx={{
                marginTop: 'auto',
                marginBottom: 'auto',
                pl: 1,
              }}
            >
              {pageConfigId ? 'Update ' : 'Create '}
                Campaign Store
            </Typography>
            <div>

              {
                !isLocalhost ? '' :
                  <FormControlLabel
                    label='localhost iframe'
                    control={
                      <Checkbox
                        checked={isLocalhostIframe}
                        onChange={(e) => handleSetIsLocalhostIframe(e.target.checked)}
                      />
                    }
                  />
              }
            </div>
          </Box>

          <Paper sx={{
            overflow: 'auto',
            minWidth: 500,
            textAlign: 'center',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
          }}>
            <Box style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              textAlign: 'left',
            }}>
              <Box sx={{
                flex: 1,
                display: 'flex',
                // flexWrap: 'wrap',
                flexDirection: 'column',
              }}>
                <Stepper
                  nonLinear
                  activeStep={activeStepIndex}
                  sx={{
                    borderBottom: '1px solid #E5E5E5',
                  }}
                >
                  {steps.map((stepEnum, stepIndex) => {
                    let label = StepEnum[stepEnum] as string
                    if (label === String(StepEnum.Components))
                      label += ` (${pageConfig.components.length})`

                    return (
                      <Step
                        key={label}
                        // completed={isStepCompleted(stepEnum)}
                        sx={{
                          px: 2,
                          height: navbarIconSize,
                          alignContent: 'center',
                        }}
                      >
                        <StepButton
                          sx={{py: '18px', transform: 'translateY(-2px)'}}
                          onClick={() => {
                            const didDiscard = handleDiscardComponentChanges()
                            if (!didDiscard) return
                            setActiveStepIndex(stepIndex)
                          }}
                        >
                          <StepLabel
                            error={isStepError(stepEnum)}
                            sx={{
                              '& .MuiStepLabel-label.Mui-active': {
                                // NOTE: css is amazing and it gives us the gift of fontWeight bold shifting width for subpixel size
                                textShadow: '0px 0px 0px currentColor, 0px 0 currentColor, 0 0px currentColor',
                                fontWeight: 'normal',
                                boxShadow: `0 2px 0 0 ${theme.palette.primary.main}`,
                              },
                            }}
                          >
                            {label}
                          </StepLabel>
                        </StepButton>
                      </Step>
                    )},
                  )}
                </Stepper>

                <Box sx={{
                  flex: 1,
                  display: 'flex',
                  position:  'relative',
                }}
                >
                  <Box sx={{
                    overflow: 'scroll',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}>
                    <Box sx={{
                      flex: 1,
                      pl: 2,
                      pr: 1,
                    }}>
                      {activeStep === StepEnum.Layout && pageConfig.layout !== 'SOCIAL_HOME' && layoutSettings}
                      {activeStep === StepEnum.Layout && pageConfig.layout === 'SINGLE_PRODUCT' && productSettings}
                      {activeStep === StepEnum.Layout && pageConfig.layout === 'CATEGORY' && categorySettings}
                      {activeStep === StepEnum.Campaign && campaignSettings}
                      {activeStep === StepEnum.Components && componentsSettings}
                      {activeStep === StepEnum.Publish && publishSettings}
                    </Box>

                  </Box>
                </Box>

                {activeStep === StepEnum.Components && <Box>
                  {/* drag bar */}
                  <Box sx={{
                    px: theme.spacing(2),
                    height: 24,
                    overflow: 'auto',
                    userSelect: 'none',
                    backgroundColor: '#546D78',
                    display: 'flex',
                    position: 'relative',
                  }}
                  >
                    <h4 style={{
                      color: 'white',
                      fontSize: '11px',
                      fontWeight: 'bold',
                      padding: 0,
                      lineHeight: '24px',
                    }}>COMPONENT SELECTOR</h4>
                    <Button
                      onMouseDown={onMouseDown}
                      onTouchStart={onMouseDown}
                      onClick={resizeComponentSelector}
                      sx={{
                        position: 'absolute',
                        left: '50%',
                        top: 0,
                        height: '100%',
                        transform: 'translate(-50%, 0)',
                        cursor: 'ns-resize',
                        display: 'flex',
                        flexDirection: 'column',
                        width: 35,
                        padding: 0,
                        '& .line': {
                          width: 35,
                          height: 2,
                          backgroundColor: 'white',
                          opacity: 0.5,
                          margin: '1px 0',
                          borderRadius: 1,
                        },
                      }}
                    >
                      <span className='line'></span>
                      <span className='line'></span>
                      <span className='line'></span>
                    </Button>
                    <Box
                      onClick={resizeComponentSelector}
                      sx={{
                        position: 'absolute',
                        right: 10,
                        top: 0,
                        height: '100%',
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        width: 20,
                        padding: 0,
                        '& path': {
                          color: 'white',
                        },
                      }}>
                      {componentSelectorHeight > 0 ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                    </Box>
                  </Box>

                  <Box ref={componentSelectorRef} sx={{
                    maxHeight: 'calc(100vh - 200px)',
                    height: `${componentSelectorHeight}px`,
                    overflow: 'hidden',
                  }}>
                    <Box sx={{
                      // overflow: 'hidden',
                      backgroundColor: 'white',
                      height: 42,
                      display: 'flex',
                      justifyContent: 'space-between',
                      borderBottom: '1px solid #E5E5E5',
                    }}
                    >
                      <Tabs
                        TabIndicatorProps={{style: {background: theme.palette.brand.urIndigo, height: '3px', bottom: 7}}}
                        value={componentPickerTab}
                        onChange={(e, val) => {
                          const didDiscard = handleDiscardComponentChanges()
                          if (!didDiscard) return
                          setComponentPickerTab(val)
                        }}
                        sx={{
                          height: 39,
                        }}
                      >
                        <Tab sx={{minHeight: '42px', padding: '0 5px', color: `${theme.palette.brand.urIndigo} !important`}}
                          value='new_edit' label={stagedComponent ? 'Edit': 'New'}
                          iconPosition='start' icon={<NewIcon />} />

                        <Tab sx={{minHeight: '42px', padding: '0 5px', color: `${theme.palette.brand.urIndigo} !important`}}
                          value='library' label='Library'
                          iconPosition='start' icon={<LibraryOutlineIcon />} />

                        <Tab sx={{minHeight: '42px', padding: '0 5px', color: `${theme.palette.brand.urGreen} !important`}}
                          value='hooks' label='Hooks'
                          iconPosition='start' icon={<HooksIcon />} />

                        <Tab sx={{minHeight: '42px', padding: '0 5px', color: `${theme.palette.brand.urOrange} !important`}}
                          value='integrations' label='Integrations'
                          iconPosition='start' icon={<IntegrationsIcon />} />
                      </Tabs>
                    </Box>

                    {/* editing section */}
                    <Box
                      boxShadow={1}
                      sx={{
                        px: theme.spacing(2),
                        overflow: 'scroll',
                        height: 'calc(100% - 50px)',
                        position: 'relative',
                      }}
                    >
                      {componentPickerTab === 'new_edit' &&
                        <Box sx={{
                          position: 'absolute',
                          width: 'calc(100% - 25px)',
                          // NOTE: workaround for some previous mui bug, in this case render this element all the time
                          // opacity: componentPickerTab === 'new_edit' ? 1 : 0,
                        }}>
                          <Box sx={{pt: '3px'}} />

                          <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}>

                            <TextField
                              style={{
                                flex: '1',
                                marginRight: '12px',
                              }}
                              required
                              inputRef={componentInternalNameRef}
                              error={!!componentInternalNameErrorString}
                              // helperText={componentInternalNameErrorString} // TODO: moves rows below
                              size='small' margin='dense' type='string' variant='outlined'
                              value={editedComponentConfig.internalName}
                              onChange={(e) => {
                                const newComponent = {...editedComponentConfig}
                                newComponent.internalName = e.target.value
                                handleSetEditedComponentConfig(newComponent)
                              }}
                              label={'Internal Name'}
                            />

                            <TextField
                              multiline
                              onBlur={() => setDescriptionMaxRows(1)}
                              onFocus={() => setDescriptionMaxRows(3)}
                              maxRows={descriptionMaxRows}
                              style={{
                                flex: '1',
                              }}
                              size='small' margin='dense' type='string' variant='outlined'
                              value={editedComponentConfig.internalDescription}
                              onChange={(e) => {
                                const newComponent = {...editedComponentConfig}
                                newComponent.internalDescription = e.target.value
                                handleSetEditedComponentConfig(newComponent)
                              }}
                              label={'Description'}
                            />
                          </Box>

                          <ComponentsPicker
                            layout={pageConfig.layout}
                            editedComponentId={editedComponentId}
                            componentConfig={editedComponentConfig}
                            setComponentConfig={handleSetEditedComponentConfig}
                            defaultVisibleInLibrary={false}
                            isRenderedInStoreFrontComposer={true}
                          />

                          <Button
                            onClick={() => {
                              editedComponentConfig.visibleInLibrary = !editedComponentConfig.visibleInLibrary
                              handleSetEditedComponentConfig(editedComponentConfig)
                            }}
                            sx={{
                              borderColor:`${theme.palette.brand.urIndigo} !important`,
                              color: `${theme.palette.brand.urIndigo} !important`,
                            }}
                            startIcon={editedComponentConfig.visibleInLibrary ? <LibraryFilledIcon /> : <LibraryOutlineIcon />}
                          >
                            Save to Library
                          </Button>

                        </Box>
                      }

                      {componentPickerTab === 'library' &&
                        <ComponentSelectorList
                          getTitle={(item) => pageConfig.pageComponentOverrides?.[item.componentId]?.title || item.title || ''}
                          allItems={pageConfig.components}
                          items={allComponents.filter((component) => shouldDisplayInCustomLibraryTab(component, pageConfig))}
                          buttonColor={theme.palette.brand.urIndigo}
                          onAddClick={handleOnAddClick}
                          // onLibraryClick={(item) => {console.log(111, 'library custom', {item})}}
                          onEditClick={(item) => {
                            if (!pageConfig.components.find(c => c.componentId === item.componentId))
                            {
                              setComponentPickerTab('new_edit')
                              handleSetEditedComponentConfig(item)
                            }
                            handleOnEditClick(item)
                          }}
                        />
                      }

                      {componentPickerTab === 'hooks' &&
                        <ComponentSelectorList
                          getTitle={(item) => pageConfig.pageComponentOverrides?.[item.componentId]?.title || item.title || ''}
                          allItems={pageConfig.components}
                          items={allComponents.filter((component) => shouldDisplayInHooksTab(component, pageConfig))}
                          buttonColor={theme.palette.brand.urGreen}
                          onAddClick={handleOnAddClick}
                          onEditClick={(item) => {
                            if (!pageConfig.components.find(c => c.componentId === item.componentId))
                            {
                              setComponentPickerTab('new_edit')
                              handleSetEditedComponentConfig(item)
                            }
                            handleOnEditClick(item)
                          }}
                        />
                      }

                      {componentPickerTab === 'integrations' &&
                        <ComponentSelectorList
                          getTitle={(item) => pageConfig.pageComponentOverrides?.[item.componentId]?.title || item.title || ''}
                          allItems={pageConfig.components}
                          items={allComponents.filter((component) => shouldDisplayInIntegrationsTab(component, pageConfig))}
                          buttonColor={theme.palette.brand.urOrange}
                          onAddClick={handleOnAddClick}
                        />
                      }
                    </Box>
                  </Box>
                </Box>}
              </Box>
            </Box>

            <Box sx={{
              // boxShadow: theme.shadows[1],
              // border: '1px solid red',
              mx: 2,
              display: 'flex',
              gap: '5px',
              justifyContent: 'space-between',
              alignItems: 'center',

              // TODO: why this offset...
              transform: 'translateY(-3px)',
              height: navbarIconSize - 7,
            }}>

              <div></div>
              <div>
                <AffectedPagesModal
                  isOpen={libraryModalOpen}
                  setIsOpen={setLibraryModalOpen}
                  isLoadingAffectedPages={isLoadingAffectedPages}
                  affectedPages={affectedPages}
                  editedComponentConfig={editedComponentConfig}
                  setReadyForUpdate={setReadyForUpdate}
                  pageConfig={pageConfig}
                />

                {/* <StorefrontModal
                  open={nextModalOpen}
                  onClose={() => {
                    setNextModalOpen(false)
                  }}
                >
                </StorefrontModal> */}


                {editedComponentId ?
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <Button
                        sx={{
                          color:`${theme.palette.brand.urIndigo} !important`,
                        }}
                        onClick={discardChanges}
                      >
                        Cancel
                      </Button>

                      <Button
                        sx={{
                          backgroundColor: stagedComponent ? `${theme.palette.brand.urIndigo} !important` : '',
                          borderColor: `${theme.palette.brand.urIndigo} !important`,
                          color: stagedComponent ? '' : `${theme.palette.brand.urIndigo} !important`,
                        }}
                        onClick={() => {
                          const isValid = validateComponentInternalName()
                          if (!isValid) return

                          // editing local, just apply
                          if (!editedComponentConfig.visibleInLibrary) {
                            setReadyForUpdate(true)
                            return
                          }
                          // editing global but not already published, just apply
                          const globalComponent = allComponents.find(c => c.componentId === editedComponentConfig.componentId)
                          if (!globalComponent) {
                            setReadyForUpdate(true)
                            return
                          }
                          // editing global and already published, show affected pages popup before proceeding
                          setLibraryModalOpen(true)
                          setIsLoadingAffectedPages(true)
                          getAffectedPages(editedComponentId).then(pages => {
                            setAffectedPages((pages || []).filter(p => p.id !== pageConfig.id))
                            setIsLoadingAffectedPages(false)
                          })
                        }}
                        variant={stagedComponent ? 'contained' : 'outlined'}
                      >
                        {stagedComponent ? 'Update' : '+ Add'}
                      </Button>

                    </Box>
                  </>
                  : <></>
                }
              </div>
            </Box>
          </Paper>
        </Paper>

        <Paper style={{
          overflow: 'auto',
          minWidth: 500,
          padding: theme.spacing(4),
          textAlign: 'center',
          flex: 1,
        }}>
          <Resizable
            width={resizeState.width}
            height={resizeState.height}
            minConstraints={[300, 533]}
            maxConstraints={[1000, 1000]}
            onResize={onResize}
            onResizeStart={() => setIsResizing(true)}
            onResizeStop={() => setIsResizing(false)}
          >
            <div
              className="box"
              style={{
                margin: 'auto',
                width: `${resizeState.width}px`,
                height: `${resizeState.height}px`,
                display: 'flex',
                flexDirection: 'column',
              }}
            >

              <div
                style={{
                  flex: 3,
                  overflow: 'hidden',
                  height: '100%',
                  width: '100%',
                  contain: 'layout',
                  position: 'relative' as any,
                  outline: '1px solid #E5E5E5',
                }}
              >
                <iframe
                  onLoad={() => setIframeLoaded(true)}
                  ref={iframeRef}
                  title="Live preview"
                  style={{
                    pointerEvents: isResizing ? 'none' : undefined as unknown as any,
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    border: 0,
                    position: 'absolute' as any,
                  }}
                  src={iframeSrc}
                />
              </div>
            </div>
          </Resizable>
        </Paper>

      </Box>
    </Root>
  )
}

export default CreateStorefront

const env = process.env.REACT_APP_ENVIRONMENT
const region = process.env.REACT_APP_DEPLOY_REGION
const host = `simplicitydx.com`

if (!env) {
  throw new Error(`Environment is not set`)
}

if (!region) {
  throw new Error(`Deploy region is not set`)
}

// // Configure Shopify Settings
// let shopifyAppName
// switch (region) {
// case `prod-use2`:
//   shopifyAppName = `simplicitydx-production-use2`
//   break
// case `stage-use2`:
//   shopifyAppName = `simplicitydx-staging`
//   break
// default:
//   shopifyAppName = `iconvert`
//   break
// }

// export const appName = shopifyAppName

const enterpriseAppNames = [`simplicitydx-production-use2`, `simplicitydx-staging`, `iconvert`]
const basicAppNames = [`social-ad-attribution-develop`]

let shopifyClientID
switch (region) {
case `prod-use2`:
  shopifyClientID = `2e1a10f9b820a439b2197258d8c9b138`
  break
case `stage-use2`:
  shopifyClientID = `f3030dc958957a76a65f68b00155a7b7`
  break
default:
  shopifyClientID = `b46cf18ee23f19e6137e1cfcc809dd30`
  break
}

let shopifyBasicClientID
switch (region) {
case `prod-use2`:
  shopifyBasicClientID = `d5cca6aa1429cbe78fab11039ee29c57`
  break
case `stage-use2`:
  shopifyBasicClientID = `b3e619d31a82b760f9b5650d050cdaa9`
  break
default:
  shopifyBasicClientID = `3194fb7e6b1a3521441ebc8d3d79fc85`
  break
}

export function getShopifyApiKey(appName: string) {
  if (enterpriseAppNames.includes(appName)) {
    return shopifyClientID
  } else if (basicAppNames.includes(appName)) {
    return shopifyBasicClientID
  }
  return shopifyBasicClientID
}

export type ShopifyAppType = 'SALES_CHANNEL' | 'NON_SALES_CHANNEL'
export function getAppType(appName: string): ShopifyAppType  {
  if (enterpriseAppNames.includes(appName)) {
    return 'SALES_CHANNEL'
  } else if (basicAppNames.includes(appName)) {
    return 'NON_SALES_CHANNEL'
  }
  return 'NON_SALES_CHANNEL'
}


// Configure Meta Settings
let metaAdsAccountConfig
switch (region) {
case `prod-use2`:
  metaAdsAccountConfig = 1500884890496556
  break
case `stage-use2`:
  metaAdsAccountConfig = 817255620172893
  break
default:
  metaAdsAccountConfig = 3953332664994013
  break
}

export const metaAdsAccountConfigId = metaAdsAccountConfig

// url used for logging into instagram. Identical except for the following query params:
// client_id is the id of our instagram app on meta.
// redirect_uri is where we bring the user to after they've logged into instagram.
let igLoginRedirect
switch (region) {
case `prod-use2`:
  igLoginRedirect = `https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=1822638155141328&redirect_uri=https://app.simplicitydx.com/app/instagram-creator-loading&response_type=code&scope=instagram_business_basic`
  break
case `stage-use2`:
  igLoginRedirect = `https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=1186948835894007&redirect_uri=https://app.stage.simplicitydx.com/app/instagram-creator-loading&response_type=code&scope=instagram_business_basic`
  break
default:
  igLoginRedirect = `https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=1585573782030688&redirect_uri=https://app.dev.simplicitydx.com/app/instagram-creator-loading&response_type=code&scope=instagram_business_basic`
  break
}
export const igLoginRedirectUrl = igLoginRedirect


// Configure Store Domain
let sDomain
switch (region) {
case `prod-use2`:
  sDomain = `.social.simplicitydx.com`
  break
case `stage-use2`:
  sDomain = `.social.stage.simplicitydx.net`
  break
default:
  sDomain = `.social.dev.simplicitydx.io`
  break
}

export const storefrontDomain = sDomain

// Configure Backend Service Endpoints
export const analyticsServiceBaseUrl = `https://analytics-service.${region}.${host}`
export const analyticsServiceBaseUrl2 = `https://${region}.analytics-service.${host}`
export const componentServiceBaseUrl = `https://${region}.component-service.${host}`
export const configAPIBaseURL = `https://config-api.${region}.${host}`
export const socialServiceBaseUrl = `https://${region}.social-service.${host}`
export const productServiceBaseUrl = `https://${region}.product-service.${host}`
export const ecommerceServiceBaseUrl = `https://${region}.ecommerce-service.${host}`

let scriptBase
switch (region) {
case `dev-use2`:
  scriptBase = `d33sc73c7119ej.cloudfront.net`
  break
case `stage-use2`:
  scriptBase = `db8entz45aaaj.cloudfront.net`
  break
default:
  scriptBase = `d1ngzejyp2v34w.cloudfront.net`
  break
}

export const scriptBaseURL = scriptBase

// Configure Launch Darkly
let ldClientId
switch (region) {
case `prod-use2`:
  ldClientId = '648b216942dea513281ed9ae'
  break
case `stage-use2`:
  ldClientId = '6512e5f9750ee712de687ba9'
  break
default:
  ldClientId = '6512e5f22ece6a122c3ca08a'
  break
}

export const ld_clientside_id = ldClientId

export const analyticsHomeRefreshMillis = 20 * 1000 // 20 seconds


const userPoolClientID = process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID
if (!userPoolClientID) {
  throw new Error('REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID is not set')
}

const userPoolID = process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID
if (!userPoolID) {
  throw new Error('REACT_APP_AWS_COGNITO_USER_POOL_ID is not set')
}

const oauthDomain = process.env.REACT_APP_AWS_COGNITO_DOMAIN
if (!oauthDomain) {
  throw new Error('REACT_APP_AWS_COGNITO_DOMAIN is not set')
}

const redirectSignIn = process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGN_IN
if (!redirectSignIn) {
  throw new Error('REACT_APP_AWS_COGNITO_REDIRECT_SIGN_IN is not set')
}

const redirectSignOut = process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGN_OUT
if (!redirectSignOut) {
  throw new Error('REACT_APP_AWS_COGNITO_REDIRECT_SIGN_OUT is not set')
}

export const awsConfig = {
  Auth: {
    Cognito: {
      userPoolClientId: userPoolClientID,
      userPoolId:  userPoolID,
      loginWith: {
        oauth: {
          domain: oauthDomain,
          scopes: ['email', 'aws.cognito.signin.user.admin'],
          redirectSignIn: redirectSignIn.split(','),
          redirectSignOut: redirectSignOut.split(','),
          responseType: 'token' as 'code' | 'token',
        },
        username: false,
        email: true,
        phone: false,
      },
      userAttributes: {
        email_verified: {
          required: true,
        },
      },
      mfa: {
        status: 'on' as 'on' | 'off' | 'optional',
      },
    },
  },
}

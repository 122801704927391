import React, { useCallback, useEffect, useState, FC, useRef } from 'react'
import { Box, Button, SelectChangeEvent, Tabs, TextField } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { ReactComponent as UploadIcon } from 'src/assets/icons/Upload.svg'
import { ReactComponent as InstagramIcon } from 'src/assets/icons/Instagram.svg'
import { ReactComponent as AddIcon } from 'src/assets/icons/Add.svg'

import PostSelector from 'src/components/SocialSelectors/PostSelector'
import { Ad, Post, PostsResponse, AdsResponse } from 'src/contexts/types'
import { UploadComponent } from './UploadComponent'
import AdSelector from 'src/components/SocialSelectors/AdSelector'
import { IntegrationLocal, IntegrationsPayload } from 'src/views/StoreIntegrations/types'
import useGenericContext from 'src/hooks/useGenericContext'
import { useLocation, useParams } from 'react-router-dom'
import { PageConfig } from 'src/types/interpretor'
import useDebouncedState from 'src/hooks/useDebouncedState'
import { parseSentences } from 'src/utils/helpers'


export type OnPostOrAdSelectedProps = {postOrAd: Post | Ad, integrationIndex: number}

const getInitialPostsResponse = () => ({
  offset: 0, posts: [], isEnd: false,
})

const getInitialAdsResponse = () => ({
  offset: 0, ads: [], isEnd: false,
})

export type SocialPickerProps = {
  defaultOpen?: boolean

  initialAdId?: string
  initialPostId?: string
  initialIntegrationIndex?: number
  manualTabOnClick?: () => void

  onPostOrAdSelected?: (p: OnPostOrAdSelectedProps) => void

  headline: string
  onHeadlineChange: (headline: string) => void
  headlineErrorString?: string
  setheadlineErrorString?: (val: string) => void

  subheadline: string
  onSubheadlineChange: (subheadline: string) => void

  campaignMedias: PageConfig['campaignMedias']
  onCampaignMediasChange: (campaignMedias: PageConfig['campaignMedias']) => void

  keepOpen?: boolean
}

export enum SocialSearchValue {
  Text = 'TEXT',
  Id = 'ID'
}

export type SocialSearchOption = {
  value: SocialSearchValue
  label: string
}

const socialSearchOptions: SocialSearchOption[] = [
  { value: SocialSearchValue.Text, label: 'Text Search' },
  { value: SocialSearchValue.Id, label: 'ID Search' },
]

export const SocialPicker: FC<SocialPickerProps> = ({
  defaultOpen = true,

  initialAdId,
  initialPostId,
  initialIntegrationIndex,
  manualTabOnClick,

  headline,
  onHeadlineChange,
  headlineErrorString,
  setheadlineErrorString,

  subheadline,
  onSubheadlineChange,

  campaignMedias,
  onCampaignMediasChange,

  onPostOrAdSelected,
  keepOpen,
}) => {
  const { getPosts, getAds, getIntegrations, getAd, getPost } = useGenericContext()
  const routeParams = useParams()
  const pageConfigId: string = routeParams['id'] || ''

  const [integrations, setIntegrations] = useState<IntegrationLocal[]>([])
  const [integrationIndex, setIntegrationIndex] = useState(0)

  const [adsResponse, setAdsResponse] = useState<AdsResponse & { offset: number, isEnd: boolean }>(getInitialAdsResponse())
  const [postsResponse, setPostsResponse] = useState<PostsResponse & { offset: number, isEnd: boolean }>(getInitialPostsResponse())
  const [selectedPost, setSelectedPost] = useState<Post | null>(null)
  const [selectedAd, setSelectedAd] = useState<Ad | null>(null)
  const [postAccountId, setPostAccountId] = useState('')
  const [adAccountId, setAdAccountId] = useState('')
  const [searchString, setSearchString] = useState('')
  const debouncedSearchString = useDebouncedState(searchString, 200)
  const nameRef: React.MutableRefObject<HTMLElement | null> = useRef<HTMLElement>(null)
  const [searchType, setSearchType] = useState<SocialSearchValue>(SocialSearchValue.Text)

  // when we select a post as the campaign image, change the page config state accordingly.
  useEffect(() => {
    if (!selectedPost) return

    onCampaignMediasChange([{
      objId: uuidv4(),
      altText: '',
      originalUrl: selectedPost.thumbnail,
      largeUrl: selectedPost.thumbnail,
      mediumUrl: selectedPost.thumbnail,
      smallUrl: selectedPost.thumbnail,
      postId: selectedPost.externalId,
      postType: 'instagram',
      postAccountId: selectedPost.accountId,
    }])
    // parse headline (first sentence) and subheadline (subsequent sentences) from the post caption
    const sentences = parseSentences(selectedPost.caption)
    const suggestedHeadline = sentences[0] || ''
    const suggestedSubHeadline = sentences.slice(1).join(' ')
    if (suggestedHeadline) onHeadlineChange(suggestedHeadline)
    if (suggestedSubHeadline) onSubheadlineChange(suggestedSubHeadline)
    onPostOrAdSelected?.({postOrAd: selectedPost, integrationIndex})

  }, [selectedPost])

  // when we select an ad as the campaign image, change the page config state accordingly.
  useEffect(() => {
    if (!selectedAd) return

    onCampaignMediasChange([{
      objId: uuidv4(),

      altText: '',
      originalUrl: selectedAd.medias?.[0].url,
      largeUrl: selectedAd.medias?.[0].url,
      mediumUrl: selectedAd.medias?.[0].url,
      smallUrl: selectedAd.medias?.[0].url,

      postId: selectedAd.externalId,
      postType: 'ad',
      postAccountId: selectedAd.accountId,
    }])

    // parse headline (first sentence) and subheadline (subsequent sentences) from the ad message
    const sentences = parseSentences(selectedAd.message)
    const suggestedHeadline = sentences[0]
    const suggestedSubHeadline = sentences.slice(1).join(' ')
    if (suggestedHeadline) onHeadlineChange(suggestedHeadline)
    if (suggestedSubHeadline) onSubheadlineChange(suggestedSubHeadline)
    onPostOrAdSelected?.({postOrAd: selectedAd, integrationIndex})
  }, [selectedAd])


  useEffect(() => {
    if (headlineErrorString && headline)
      setheadlineErrorString && setheadlineErrorString('')
  }, [headlineErrorString, headline])


  // set initial integration index if selected postAccountId exists.
  useEffect(() => {
    if (!integrations.length) return

    if (postAccountId) {
      const index = integrations.findIndex(x => x.externalId === postAccountId)
      if (index > -1) setIntegrationIndex(index)
    }
    else if (adAccountId) {
      const index = integrations.findIndex(x => x.externalId === adAccountId)
      if (index > -1) setIntegrationIndex(index)
    }
    else if (initialIntegrationIndex !== undefined) {
      if (initialIntegrationIndex === -1)
        setIntegrationIndex(integrations.length -1)
      else setIntegrationIndex(initialIntegrationIndex)
    }
  }, [integrations, postAccountId, adAccountId])

  // fetch our meta integrations.
  useEffect(() => {
    const fetchIntegrations = async () => {
      try {
        const response: IntegrationsPayload | undefined = (await getIntegrations())?.data
        if (response) {
          let ints: IntegrationLocal[] = []

          response.forEach(x => {
            x.adAccounts.forEach((integration) => {
              ints.push({
                label: integration.name,
                externalId: integration.externalId + '',
                icon: AddIcon,
                type: 'meta-ads',
              })
            })

            x.igAccounts.forEach((integration) => {
              ints.push({
                label: integration.name,
                externalId: integration.externalId + '',
                icon: InstagramIcon,
                type: 'instagram',
              })
            })
          })
          ints = ints.filter((x: any) => (x.type !== 'meta-ad') && (x.type !== 'meta_ads'))
          ints.push({ externalId: '', label: 'Manual', icon: UploadIcon, type: 'manual' })
          setIntegrations(ints)
        } else {
          setIntegrations([{ externalId: '', label: 'Manual', icon: UploadIcon, type: 'manual' }])
        }
      } catch (e) {
        setIntegrations([{ externalId: '', label: 'Manual', icon: UploadIcon, type: 'manual' }])
      }
    }
    fetchIntegrations()
  }, [])

  const campaignMediasIdsString = campaignMedias.map(x => x.postId).join(',')

  // if the campaign has a post or ad for an image, set the state accordingly.
  useEffect(() => {
    const doAsync = async () => {
      const initialPost = campaignMedias?.find(x => x.postType === 'instagram')
      if (initialPost) {
        const asyncGetPost = async () => {
          setPostAccountId(initialPost.postAccountId!)
          const response = await getPost(initialPost.postAccountId!, initialPost.postId!)
          response?.data && setSelectedPost(response.data)
        }
        asyncGetPost()
      }

      const initialAd = campaignMedias?.find(x => x.postType === 'ad')
      if (initialAd) {
        const asyncGetAd = async () => {
          setAdAccountId(initialAd.postAccountId!)
          const response = await getAd(initialAd.postAccountId!, initialAd.postId!)
          response?.data && setSelectedAd(response.data)
        }
        asyncGetAd()
      }
    }
    doAsync()
  }, [getAd, getPost, pageConfigId, campaignMediasIdsString])

  useEffect(() => {
    if (!initialAdId) return
    if (!integrations.length) return
    const integration = integrations[initialIntegrationIndex || 0]
    getAds(integration.externalId, 1, 0, '', initialAdId)
      .then(response => {
        const initialAd = response?.data.ads[0]
        if (initialAd) setSelectedAd(initialAd)
        else console.warn('Initial ad not found: ', {initialAdId, response})
      })
  }, [integrations])

  useEffect(() => {
    if (!initialPostId) return
    if (!integrations.length) return
    const integration = integrations[initialIntegrationIndex || 0]
    getPosts(integration.externalId, 1, 0, '', initialPostId)
      .then(response => {
        const initialPost = response?.data.posts[0]
        if (initialPost) setSelectedPost(initialPost)
        else console.warn('Initial Post not found: ', {initialPostId, response})
      })
  }, [integrations])

  const getNextAds = useCallback(async () => {
    const integration = integrations[integrationIndex]
    if (!integration) return
    if (adsResponse.isEnd) return

    if (integration.type === 'meta-ads') {
      const searchArg = searchType === SocialSearchValue.Text ? debouncedSearchString : ''
      const adIdsArg = searchType === SocialSearchValue.Id ? debouncedSearchString : ''

      const limit = 10
      const response = await getAds(integration.externalId, limit, adsResponse.offset, searchArg, adIdsArg)
      response?.data && setAdsResponse((prevAdsResponse) => {
        return {
          isEnd: response.data.ads.length < limit,
          offset: adsResponse.offset + response.data.ads.length,
          ads: (debouncedSearchString && adsResponse.offset === 0) ? [...response.data.ads] : [
            ...prevAdsResponse.ads,
            ...response.data.ads,
          ],
        }
      })
    }
  }, [integrations, integrationIndex, adsResponse.isEnd, adsResponse.offset, getAds, debouncedSearchString, searchType])

  const getNextPosts = useCallback(async () => {
    const integration = integrations[integrationIndex]
    if (!integration) return
    if (postsResponse.isEnd) return

    if (integration.type === 'instagram') {
      const searchArg = searchType === SocialSearchValue.Text ? debouncedSearchString : ''
      const postIdsArg = searchType === SocialSearchValue.Id ? debouncedSearchString : ''

      const limit = 10
      const response = await getPosts(integration.externalId, limit, postsResponse.offset, searchArg, postIdsArg)
      response?.data && setPostsResponse((prevPostsResponse) => {
        return {
          isEnd: response.data.posts.length < limit,
          offset: postsResponse.offset + response.data.posts.length,
          posts: (debouncedSearchString && postsResponse.offset === 0) ? [...response.data.posts] : [
            ...prevPostsResponse.posts,
            ...response.data.posts,
          ],
        }
      })
    }

  }, [getPosts, integrationIndex, integrations, postsResponse.isEnd, postsResponse.offset, debouncedSearchString, searchType])

  // re-fetch posts / ads if we change the integration we're looking at or change the search string.
  useEffect(() => {
    getNextPosts()
    getNextAds()
    // Changing offset will trigger a re-fetch
  }, [integrations, integrationIndex, debouncedSearchString, searchType])

  const onRenderNext = (() => {
    getNextPosts()
    getNextAds()
  })

  const handleSearchChange = useCallback((val: string) => {
    setSearchString(val)
    if (integrationIndex === integrations.length - 1) return
    if (integrations[integrationIndex]?.type === 'instagram') {
      setPostsResponse(getInitialPostsResponse())
    }
    if (integrations[integrationIndex]?.type === 'meta-ads') {
      setAdsResponse(getInitialAdsResponse())
    }
  }, [integrationIndex, integrations])

  const handleSearchTypeChange = (event: SelectChangeEvent<SocialSearchValue>) => {
    const value = event.target.value as SocialSearchValue // Cast value to SocialSearchValue
    if (Object.values(SocialSearchValue).includes(value)) {
      setSearchType(value)
    } else {
      console.error(`Invalid search type value: ${value}`)
    }
  }


  const setIntegrationIndexFn = ((integration, index) => {
    if (index === integrationIndex) return
    if (integration.type === 'instagram') {
      setPostAccountId(integration.externalId)
      setSelectedPost(null)
      setPostsResponse(getInitialPostsResponse())
      setAdAccountId('')
    }

    if (integration.type === 'meta-ads') {
      setAdAccountId(integration.externalId)
      setSelectedAd(null)
      setAdsResponse(getInitialAdsResponse())
      setPostAccountId('')
    }

    if (campaignMedias[0]?.postType) {
      onSubheadlineChange('')
      onCampaignMediasChange([])
    }
    setIntegrationIndex(index)
    if (index === integrations.length -1)
      manualTabOnClick?.()
  })

  const onSelect = ((val) => {
    if (integrations[integrationIndex]?.type === 'instagram') setSelectedPost(val)
    if (integrations[integrationIndex]?.type === 'meta-ads') setSelectedAd(val)
  })

  const onCampaignImageChange = ((val) => {
    onCampaignMediasChange(val ? [{
      objId: uuidv4(),
      altText: '',
      originalUrl: val,
      largeUrl: val,
      mediumUrl: val,
      smallUrl: val,
    }] : [])
  })

  const onRemovePreview = (() => {
    onCampaignMediasChange([{
      objId: uuidv4(),
      altText: '',
      originalUrl: '',
      largeUrl: '',
      mediumUrl: '',
      smallUrl: '',
      video: {
        url: '',
        thumbnail: '',
      },
    }])
  })

  const onCampaignVideoChange = (async (val: {
    url: string
    thumbnail: string
  }) => {

    if (val) {
      onCampaignMediasChange(val ? [{
        altText: '',
        objId: uuidv4(),
        videoThumbnail: val.thumbnail,
        isVideo: true,
        originalUrl: val.thumbnail,
        largeUrl: val.thumbnail,
        mediumUrl: val.thumbnail,
        smallUrl: val.thumbnail,
        video: val,
      }] : [])
    }
  })

  const onCampaignDescriptionChange = ((val) => {
    onSubheadlineChange(val)
  })

  const onCampaignNameChange = ((val) => {
    onHeadlineChange(val)
  })

  const campaignImage = campaignMedias[0]?.video?.thumbnail || campaignMedias[0]?.mediumUrl || ''
  const posts = postsResponse.posts
  const ads = adsResponse.ads
  const campaignName = headline
  const campaignDescription = subheadline

  return (
    <div>
      <div
        style={{
          display: 'flex',
        }}
      >
        <Tabs
          style={{
            flex: 2,
            position: 'relative',
          }}
          scrollButtons='auto'
          ScrollButtonComponent={({ direction, className, onClick, disabled }) => {
            return (
              <div
                onClick={onClick}
                className={className}
              >
                <Button
                  size='small'
                  style={{
                    minWidth: '40px',
                    color: disabled ? 'lightgrey' : 'inherit',
                  }}
                >
                  {direction === 'right' ? <ArrowRightIcon /> : <ArrowLeftIcon />}
                </Button>
              </div>
            )
          }}
          variant='scrollable'
          TabIndicatorProps={{ children: <span /> }}
          classes={{
            indicator: 'indicator',
            // flexContainer: {{ display: unset}},
          }}
          value={integrationIndex}
          indicatorColor='primary'
        >
          {
            integrations.map((integration, i) => {
              return (
                <Button
                  key={i}
                  onClick={() => setIntegrationIndexFn(integration, i)}
                  color='inherit'
                  sx={{
                    minWidth: 'fit-content',
                  }}
                >
                  <Box sx={{
                    display: 'flex',
                  }}>
                    <Box style={{
                      paddingRight: '6px',
                      paddingTop: '3px',
                    }}>
                      {typeof integration.icon !== 'undefined' &&
                        <integration.icon style={{
                          width: 20,
                          height: 20,
                        }} />
                      }
                    </Box>
                    <Box>{integration.label}</Box>
                  </Box>
                </Button>
              )
            })
          }
        </Tabs>
      </div>
      {
        integrationIndex === integrations.length - 1 ?
          <div
            style={{
              display: 'flex',
            }}
          >
            <div style={{
              margin: '6px 0px',
              marginRight: '12px',
            }}>
              <UploadComponent
                onRemovePreview={onRemovePreview}
                selectedImage={campaignImage}
                onImageUrlChange={onCampaignImageChange}
                onCampaignVideoChange={onCampaignVideoChange}
              />
            </div>

            <div style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
            }}>
              <TextField
                style={{
                  flex: 1,
                }}
                label="Headline"
                margin="dense" type="string" variant="outlined"

                inputRef={(element) => {
                  if (nameRef?.current) nameRef.current = element
                }}
                error={!!headlineErrorString}
                helperText={headlineErrorString}

                value={campaignName}
                onChange={(e) => {
                  onCampaignNameChange(e.target.value)
                }}
              />
              {/* <TextField
                style={{
                  flex: 1,
                }}
                margin="dense" type="string" variant="outlined"
                label="Campaign Image/Video Upload URL (.mp4)"

                value={campaignImage}
                onChange={(e) => {
                  const fileName = e.target.value?.trim()
                  if (fileName?.endsWith('.mp4')) {
                    onCampaignVideoChange({ url: e.target.value?.trim(), thumbnail: '' })
                  } else {
                    onCampaignImageChange(fileName)
                  }
                }}
              /> */}
              <TextField
                multiline minRows={7} maxRows={10}
                margin='dense' type='string' variant='outlined'
                sx={{ flex: 1 }}

                label='Optional Subtitle'
                value={campaignDescription}
                onChange={(e) => onCampaignDescriptionChange(e.target.value)}
              />
            </div>
          </div>
          : integrations[integrationIndex]?.type === 'instagram' ? <Box>
            <PostSelector
              onRenderNext={onRenderNext}
              onSelect={onSelect}
              items={posts}
              selected={selectedPost}
              nameRef={nameRef}
              nameErrorString={headlineErrorString}
              campaignName={campaignName}
              onCampaignNameChange={onCampaignNameChange}
              campaignDescription={campaignDescription}
              onCampaignDescriptionChange={onCampaignDescriptionChange}
              defaultOpen={defaultOpen}
              handleSearchChange={handleSearchChange}
              searchString={searchString}
              handleSearchTypeChange={handleSearchTypeChange}
              searchType={searchType}
              searchTypeOptions={socialSearchOptions}
              keepOpen={keepOpen}
            /></Box>
            : integrations[integrationIndex]?.type === 'meta-ads' ? <AdSelector
              onRenderNext={onRenderNext}
              onSelect={onSelect}
              items={ads}
              selected={selectedAd}
              nameRef={nameRef}
              nameErrorString={headlineErrorString}
              campaignName={campaignName}
              onCampaignNameChange={onCampaignNameChange}
              campaignDescription={campaignDescription}
              onCampaignDescriptionChange={onCampaignDescriptionChange}
              defaultOpen={defaultOpen}
              handleSearchChange={handleSearchChange}
              searchString={searchString}
              handleSearchTypeChange={handleSearchTypeChange}
              searchType={searchType}
              searchTypeOptions={socialSearchOptions}
              keepOpen={keepOpen}
            />
              : ''
      }
    </div>
  )
}

import React, { FC, useEffect } from 'react'
import SdxLoading from 'src/components/SdxLoading/SdxLoading'
import { useHistory } from 'react-router-dom'
import { getRoutePath } from 'src/routes'
import useGenericContext from 'src/hooks/useGenericContext'
import { logging } from 'src/utils/logging'

const InstagramCreatorLoadingPage: FC = () =>  {
  // this page will have a code from the instagram login in the query params.
  // we need to provide this to the backend, which will actually set up the integration.
  // when that's done, we'll redirect back to the store integrations, where the new instagram account will be displayed.
  const params = new URLSearchParams(window.location.search)
  const code = params.get('code')
  const history = useHistory()
  const {postInstagramCreator} = useGenericContext()

  useEffect(() => {
    if (!code) return
    const setupIntegration = async () => {
      const res = await postInstagramCreator({code: code})
      if (res.err === ''){
        history.push(getRoutePath('store_integrations'))
      } else {
        window.alert('error setting up instagram integration')
        logging(res.err, { tags: { section: 'instagramCreatorLoading' } })
      }
    }
    setupIntegration()
  }, [code, history, postInstagramCreator])

  return (
    <SdxLoading subText={'Setting up Instagram Creator integration...'}/>
  )
}

export default InstagramCreatorLoadingPage

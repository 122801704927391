import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'src/assets/css/prism.css'
import 'src/assets/css/react-resizable.css'
import 'src/assets/css/scrollbars.css'
import 'typeface-ibm-plex-sans'
import '@aws-amplify/ui-react/styles.css' // default theme
import React, { ReactElement } from 'react'
import { Authenticator } from '@aws-amplify/ui-react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { Provider } from 'react-redux'
import { enableES5 } from 'immer'
import { ThemeProvider, StyledEngineProvider } from '@mui/material'
import { BrowserRouter } from 'react-router-dom'

import 'src/i18n'

import { ld_clientside_id } from'src/config'

import WebApp from 'src/WebApp'
import ShopifyApp from './ShopifyApp'
import * as serviceWorker from 'src/serviceWorker'
import store from 'src/appRedux/store'
import ErrorBoundry from 'src/components/ErrorBoundry'
import { isShopifyEmbedded } from '@shopify/app-bridge-utils'
import ShopifyAuthHome from 'src/views/ShopifyAuthHome'
import { GenericProvider } from './contexts/GenericContext'
import { createCustomTheme } from 'src/theme'
import { AppBridgeProvider } from './contexts/AppBridgeProvider'
import { AuthProvider } from 'src/contexts/CognitoContext'
import { Amplify } from 'aws-amplify'
import { awsConfig } from 'src/config'
import { ProviderConfig } from 'launchdarkly-react-client-sdk'
import {
  AppProvider,
} from '@shopify/polaris'

// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }


Sentry.init({
  dsn: 'https://e93e485c95d64e6182dd4686b7b00eff@o4504523710332928.ingest.sentry.io/4504523720032256',
  integrations: [
    new Integrations.BrowserTracing(),
    new Sentry.Integrations.Breadcrumbs({console: false}),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
  environment: process.env.REACT_APP_SENTRY_ENV,
  release: process.env.REACT_APP_SENTRY_RELEASE,
})

enableES5()

// Configure Amplify with your AWS configuration
Amplify.configure(awsConfig)

const params = new URLSearchParams(window.location.search)
const queryParams = window.location.search.split('?')[1]

// We get these when shopify calls us initially
const hmac = params.get('hmac') || ''
const shop = params.get('shop') || ''
const timestamp = params.get('timestamp') || ''

// We get these additional query params when shopify redirects to /authorize endpoint
const code = params.get('code') || ''
const host = params.get('host') || ''
const state = params.get('state') || ''
// TODO: instagram also uses a code query param when we auth. figure out a better way to prevent the fe from thinking its a shopify code.
const path = window.location.pathname
const isGetRequestFromShopify: boolean = (hmac && shop && Boolean(timestamp)) || (Boolean(code) && !path.includes('instagram-creator-loading'))

const theme = createCustomTheme()

const ldExtraConfig: ProviderConfig = {
  clientSideID: ld_clientside_id,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  flags: {
    'campaign_stores': false,
    'klaviyo': false,
    'Meta_download': false,
    'plan_selection': false,
    'analytics': false,
    'permalink_config':false,
  },
  options: { streaming: false  },
}

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

// What we have now is essentially three apps rendered conditionally.
// The first is the ShopifyHome, which is rendered if the app is embedded in shopify.
// The second is the ShopifyAuthHome, which is rendered we get an installation request from Shopify.
// The third is our normal app home
// We should not extend this pattern further, it could get more complicated

const isShopify = isShopifyEmbedded()

let app: ReactElement

const wrapInGeneralProviders = (appState: ReactElement) => {
  appState = <ThemeProvider theme={theme}>{appState}</ThemeProvider>
  appState = <StyledEngineProvider injectFirst>{appState}</StyledEngineProvider>
  appState = <Provider store={store}>{appState}</Provider>
  appState = <GenericProvider view={isShopify ? 'shopify': 'web'} host={host} shop={shop}>{appState}</GenericProvider>
  return appState
}

const wrapInAuthProviders = (appState: ReactElement) => {
  appState = <AuthProvider>{appState}</AuthProvider>
  appState = <Authenticator.Provider>{appState}</Authenticator.Provider>
  return appState
}

if (isShopify) {
  app = <ShopifyApp shop={shop} host={host} ldExtraConfig={ldExtraConfig} />
  app = wrapInGeneralProviders(app)
  app = <AppBridgeProvider>{app}</AppBridgeProvider>
  app = <AppProvider i18n={{}}>{app}</AppProvider>
} else if (isGetRequestFromShopify) {
  app = <ShopifyAuthHome shop={shop} hmac={hmac} timestamp={timestamp} code={code} host={host} state={state} queryParams={queryParams} />
} else {
  app = <WebApp ldExtraConfig={ldExtraConfig} />
  app = wrapInGeneralProviders(app)
  app = wrapInAuthProviders(app)
}

app = <BrowserRouter>{app}</BrowserRouter>
app = <Sentry.ErrorBoundary fallback={<ErrorBoundry />}>{app}</Sentry.ErrorBoundary>

root.render(app)

serviceWorker.register()

import React, { FC, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { ProductItemFull } from 'src/components/ProductSelectorLarge'
import { Category } from 'src/contexts/types'
import useGenericContext from 'src/hooks/useGenericContext'
import { logging } from 'src/utils/logging'
import { AllProductsInCategoryInput } from './inputs/AllProductsInCategory'
import { SimilarProductsInput } from './inputs/SimilarProducts'
import { HtmlInput } from './inputs/Html'
import { ManualCategoryNavigationInput } from './inputs/ManualCategoryNavigation'
import { ManualProductSelectionInput } from './inputs/ManualProductSelection'
import { KlaviyoInput } from './inputs/Klaviyo'
import { TitleAndTypeInput } from './inputs/TitleAndTypePicker'
import { ContainerConfig } from 'storefront-interpreter/src/api/components'
import { Storybook } from './inputs/Storybook'
import { Banner } from './inputs/Banner'
import { Youtube } from './inputs/Youtube'
import { AltsInCategoryInput } from './inputs/AltsInCategory'
import { ComponentTypeResponse, getCategoryFields, getExtendedFields, getFullFields, getMinifiedFields } from '../Components/types'
import { DisplayTypesEnum } from '../ComponentSettingsPage/types'
import { PageConfig } from 'storefront-interpreter/src/config'
import { isComponentHiddenOnLayout } from 'src/utils/classifyComponents'
import { deepCloneJson } from 'src/utils/helpers'

const PREFIX = 'ComponentsPicker'

const classes = {
  htmlHookInput: `${PREFIX}-htmlHookInput`,
}

const Root = styled('div')(({theme}) => ({
  [`& .${classes.htmlHookInput}`]: {
    '& textarea': {
      maxHeight: 100,
      overflow: 'auto!important',
    },
  },
}))

const backgroundGradient = `linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 10px, rgba(255,255,255,1) calc(100% - 10px), rgba(255,255,255,0) 100%)`

export const ComponentsPicker: FC<{
  hideTitleAndType?: boolean
  hideComponentTypeDropdown?: boolean
  openWhenNotSelected?: boolean
  editedComponentId?: string
  componentConfig?: ContainerConfig
  setComponentConfig: (cfg: ContainerConfig) => void
  defaultVisibleInLibrary: boolean
  error?: boolean
  label?: string
  layout: PageConfig['layout']
  isRenderedInStoreFrontComposer?: boolean
}> = ({
  hideTitleAndType,
  hideComponentTypeDropdown,
  openWhenNotSelected,
  editedComponentId,
  componentConfig,
  setComponentConfig,
  defaultVisibleInLibrary,
  error,
  label,
  layout,
  isRenderedInStoreFrontComposer,
}) => {
  const {getCategories, getProductsByIds, storePrimitiveSettings} = useGenericContext()
  const [componentsSearching, setComponentsSearching] = React.useState<boolean>(false)

  const [currentSelectedCategoryMap, setCurrentSelectedCategoryMap] = React.useState<Record<string, Category>>({})
  const [currentSelectedProductMap, setCurrentSelectedProductMap] = React.useState<Record<string, ProductItemFull>>({})

  // NOTE: this produces a literal instead of a reference, so we can use it as a useEffect dependency without triggering a recursion
  const currentSelectedProductIdsJoined = Object.keys(currentSelectedProductMap).join(',')

  // TODO: this whole logic should not be happening here but at the top level parent component that stores componentConfig
  useEffect(() => {
    // TODO: commented this out as it seems that a call to setComponentConfig is needed as a sideeffect even when not editing a component
    // if (!editedComponentId) return
    if (!componentConfig) return
    if (!storePrimitiveSettings) return

    const newCfg = deepCloneJson(componentConfig)
    const type = newCfg.contentConfiguration.type

    if (type === 'STOREFRONT_PAGE_PRIMITIVE') {
      if (!newCfg.contentConfiguration.value.uiSettings.imageFit)
        newCfg.contentConfiguration.value.uiSettings.imageFit = storePrimitiveSettings.storefront.uiSettings.imageFit

      if (!newCfg.contentConfiguration.value.uiSettings.fields.length)
        newCfg.contentConfiguration.value.uiSettings.fields = storePrimitiveSettings.storefront.displayType === DisplayTypesEnum.MINIFIED ? getMinifiedFields() : getExtendedFields()
    }
    // only two kinds of components should use the product primitive storefront settings: product primitive configs, and all products in category components.
    else if (type === 'PRODUCT_PRIMITIVE' || (type ==='CATEGORY_PRIMITIVE' && componentConfig.componentType === 'ALL_PRODUCTS_IN_CATEGORY')) {
      if (!newCfg.contentConfiguration.value.uiSettings.imageFit)
        newCfg.contentConfiguration.value.uiSettings.imageFit = storePrimitiveSettings.product.uiSettings.imageFit

      if (!newCfg.contentConfiguration.value.uiSettings.fields.length)
        newCfg.contentConfiguration.value.uiSettings.fields = storePrimitiveSettings.product.displayType === DisplayTypesEnum.MINIFIED ? getMinifiedFields() : getFullFields()
    }
    else if (type === 'CATEGORY_PRIMITIVE') {
      if (!newCfg.contentConfiguration.value.uiSettings.imageFit)
        newCfg.contentConfiguration.value.uiSettings.imageFit = storePrimitiveSettings.category.uiSettings.imageFit

      if (!newCfg.contentConfiguration.value.uiSettings.fields.length)
        newCfg.contentConfiguration.value.uiSettings.fields = storePrimitiveSettings.category.displayType === DisplayTypesEnum.MINIFIED ? getMinifiedFields() : getCategoryFields()
    }
    else {
      return
    }
    if (JSON.stringify(newCfg) !== JSON.stringify(componentConfig)) // prevent recursion
      setComponentConfig(newCfg)
  }, [componentConfig?.contentConfiguration.type, componentConfig, editedComponentId, storePrimitiveSettings])

  useEffect(() => {
    async function fetchSelectedProducts() {
      const productMap: Record<string, ProductItemFull> = currentSelectedProductMap
      try {
        if (componentConfig?.contentConfiguration.type === 'CATEGORY_PRIMITIVE' || componentConfig?.contentConfiguration.type === 'PRODUCT_PRIMITIVE' || componentConfig?.contentConfiguration.type === 'MEDIA_PRIMITIVE') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const productIds = componentConfig?.contentConfiguration.value.apiSettings.productIds || []
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const blacklistedProducts = componentConfig?.contentConfiguration.value.apiSettings.blacklistedProductIds || []
          const allProductIds = [...productIds, ...blacklistedProducts]
          const products = await getProductsByIds(allProductIds)
          products?.forEach(function (product) { productMap[product.productId] = product })
          setCurrentSelectedProductMap({...productMap})
        }
      } catch (err) {
        setCurrentSelectedProductMap({...productMap})
        logging(err, { tags: { section: 'get curent selected category in component picker' } })
      }
    }
    fetchSelectedProducts()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  }, [componentConfig?.contentConfiguration.type, componentConfig?.contentConfiguration.value.apiSettings.blacklistedProductIds, componentConfig?.contentConfiguration.value.apiSettings.blacklisted_products, componentConfig?.contentConfiguration.value.apiSettings.productIds, currentSelectedProductIdsJoined, getProductsByIds])

  useEffect(() => {
    async function fetchSelectedCategory() {
      setComponentsSearching(true)
      const categoryMap: Record<string, Category> = currentSelectedCategoryMap
      try {
        let categoryIds: string[] = []
        if (componentConfig?.contentConfiguration.type === 'CATEGORY_PRIMITIVE') {
          categoryIds = categoryIds.concat(componentConfig?.contentConfiguration.value.apiSettings.categoryIds)
        }
        if (componentConfig?.contentConfiguration.type === 'PRODUCT_PRIMITIVE' && componentConfig?.contentConfiguration.value.apiSettings.relatedCategoryIds) {
          categoryIds = [...categoryIds, ...componentConfig?.contentConfiguration.value.apiSettings.relatedCategoryIds]
        }
        if (categoryIds.length < 1) return
        const categories = await getCategories(categoryIds)
        if (categories) {
          categories.forEach(function (categoryItem) { categoryMap[categoryItem.id] = categoryItem })
        }
        setCurrentSelectedCategoryMap(categoryMap)
        setComponentsSearching(false)
      } catch (err) {
        setCurrentSelectedCategoryMap(categoryMap)
        logging(err, { tags: { section: 'get curent selected category in component picker' } })
      }
    }
    fetchSelectedCategory()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  }, [componentConfig.componentType, componentConfig?.contentConfiguration.type, componentConfig?.contentConfiguration.value.apiSettings, componentConfig?.contentConfiguration.value.apiSettings.relatedCategoryIds, currentSelectedCategoryMap, getCategories])

  const relatedCategory = componentConfig?.contentConfiguration.type === 'PRODUCT_PRIMITIVE' && componentConfig?.contentConfiguration.value.apiSettings.relatedCategoryIds ? currentSelectedCategoryMap[componentConfig?.contentConfiguration.value.apiSettings.relatedCategoryIds[0]] : undefined

  if (!componentConfig) return <span />

  return (
    <Root>
      <div
        style={{
          background: backgroundGradient,
        }}
      >
        {!hideTitleAndType &&
          <TitleAndTypeInput
            hideComponentTypeDropdown={hideComponentTypeDropdown}
            componentConfig={componentConfig}
            setComponentConfig={setComponentConfig}
            currentSelectedProductMap={currentSelectedProductMap}
            currentSelectedCategoryMap={currentSelectedCategoryMap}
            defaultVisibleInLibrary={defaultVisibleInLibrary}
            componentsListFilter={componentListItem => {
              if (!layout) return true
              // NOTE: casting here as we don't need the rest of props
              const component = {componentType: componentListItem.value} as ContainerConfig
              if (isComponentHiddenOnLayout(component, layout)) return false
              return true
            }}
            storePrimitiveSettings={storePrimitiveSettings}
          />
        }
        {
          componentConfig.contentConfiguration.type === 'KLAVIYO_PRIMITIVE' &&
          <KlaviyoInput
            componentConfig={componentConfig}
            setComponentConfig={setComponentConfig}
            backgroundGradient={backgroundGradient}
          />
        }
        {
          componentConfig.contentConfiguration.type === 'HTML_PRIMITIVE' &&
          <HtmlInput
            componentConfig={componentConfig}
            setComponentConfig={setComponentConfig}
            backgroundGradient={backgroundGradient}
            inputClassName={classes.htmlHookInput}
          />
        }
        {
          componentConfig.componentType === 'SIMILAR_PRODUCTS' &&
          <SimilarProductsInput
            componentConfig={componentConfig}
            setComponentConfig={setComponentConfig}
            relatedCategory={relatedCategory}
            currentSelectedCategoryMap={currentSelectedCategoryMap}
            currentSelectedProductMap={currentSelectedProductMap}
            error={error}
            label={label}
            productSelectorLabel='Comparison Product For Preview Only'
            isRenderedInStoreFrontComposer={isRenderedInStoreFrontComposer}
          />
        }
        {
          componentConfig.componentType === 'VISUAL_COLLECTION' &&
          <SimilarProductsInput
            componentConfig={componentConfig}
            setComponentConfig={setComponentConfig}
            relatedCategory={relatedCategory}
            currentSelectedCategoryMap={currentSelectedCategoryMap}
            currentSelectedProductMap={currentSelectedProductMap}
            error={error}
            label={label}
          />
        }
        {
          componentConfig.componentType === 'ALTERNATES_IN_CATEGORY' &&
          <AltsInCategoryInput
            componentConfig={componentConfig}
            setComponentConfig={setComponentConfig}
            label={label}
          />
        }
        {
          componentConfig.componentType === 'ALL_PRODUCTS_IN_CATEGORY' &&
          <AllProductsInCategoryInput
            componentConfig={componentConfig}
            setComponentConfig={setComponentConfig}
            currentSelectedProductMap={currentSelectedProductMap}
            currentSelectedCategoryMap={currentSelectedCategoryMap}
            backgroundGradient={backgroundGradient}
            openWhenNotSelected={openWhenNotSelected}
            error={error}
            label={label}
          />
        }
        {
          componentConfig.componentType === 'MANUAL_CATEGORY_NAVIGATION' &&
          <ManualCategoryNavigationInput
            componentConfig={componentConfig}
            setComponentConfig={setComponentConfig}
            currentSelectedCategoryMap={currentSelectedCategoryMap}
            backgroundGradient={backgroundGradient}
          />
        }
        {
          componentConfig.componentType === 'MANUAL_PRODUCT_SELECTION' &&
          <ManualProductSelectionInput
            componentConfig={componentConfig}
            setComponentConfig={setComponentConfig}
            currentSelectedProductMap={currentSelectedProductMap}
            backgroundGradient={backgroundGradient}
            openWhenNotSelected={openWhenNotSelected}
          />
        }
        {
          componentConfig.componentType === 'CAMPAIGN_STORYBOOK' &&
          <Storybook
            componentConfig={componentConfig}
            setComponentConfig={setComponentConfig}
          />
        }
        {
          componentConfig.componentType === 'BANNER' &&
          <Banner
            componentConfig={componentConfig as ComponentTypeResponse & { contentConfiguration: { type: 'MEDIA_PRIMITIVE' } }}
            setComponentConfig={setComponentConfig}
            currentSelectedProductMap={currentSelectedProductMap}
            storePrimitiveSettings={storePrimitiveSettings}
          />
        }
        {
          componentConfig.contentConfiguration.type === 'YOUTUBE_PRIMITIVE' &&
          <Youtube
            componentConfig={componentConfig}
            setComponentConfig={setComponentConfig}
            backgroundGradient={backgroundGradient}
            inputClassName={classes.htmlHookInput}
          />
        }
      </div>
    </Root>
  )
}

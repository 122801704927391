import type { User } from 'src/types/user'
import type { Store } from 'src/types/auth'
import type { ReactNode } from 'react'
import { ImageType, PageStatus } from 'src/types/interpretor'
import { RawImage } from 'src/components/ProductSelectorLarge'
import { BarElement } from 'src/views/CampaignAnalytics/duck/barElement'
import { ComponentTypeResponse } from 'src/views/Components/types'
import { PageConfig } from 'src/types/interpretor'
import { CurrencyCode } from 'src/utils/currencySymbolMap'

import { GetTopCampaigns2 } from './api/getTopCampaigns'
import { GetTopSocialChannels2 } from './api/getTopSocialChannels'
import { GetLatestOrders2 } from './api/getLatestOrders'
import { GetCampaignTickers } from './api/getCampaignTickers'
import { GetCampaignSummaryMetrics } from './api/getCampaignSummaryMetrics'
import { GetCampaignAggregateDayOfWeek } from './api/getCampaignAggregateDayOfWeek'
import { GetOptimalPage } from './api/getOptimalPage'
import { GetManualPage } from './api/getManualPage'
import { GetPageMetrics } from './api/getPageMetrics'
import { GetMetaDownloadUrl } from './api/getMetaDownloadUrl'
import { GetMetaDownloadUrlV2 } from './api/getMetaDownloadUrlV2'
import { GetCategories, GetPost, GetPosts, GetProductsByIds, SearchCategories, SearchProducts, UploadStorefrontImage } from './api/storefrontsApi'
import { GetCampaignAnalytics, GetPerformanceAnalytics } from './api/campaignAnalyticsApi'
import { PublishComponent, GetComponents, GetComponent } from './api/componentsApi'
import { GetAllDefaultPages, CreateDynamicPages } from './api/dynamicPagesApi'
import { GetAd } from './api/getAdApi'
import { GetAds } from './api/getAdsApi'
import { GetAffectedPages } from './api/getAffectedPages'
import { GetPageConfig } from './api/getPageConfigApi'
import { GetCurrencyCode, GetCampaignTopChannels, GetTopCampaigns, GetLatestOrders, GetTotalRevenue, GetCampaignRevenueGraph } from './api/homeAnalyticsApi'
import { GetIntegrations, GetMetaPixels, PostInstagramCreator, SaveCodeGetIntegrations, SetMetaPixel } from './api/integrationsApi'
import { PublishPageConfig } from './api/publishPageConfigApi'
import { SaveStoreSettings, GetStoreSettings, SaveCustomDomain, GetActiveCustomDomain, GetThirdParty, SaveThirdParty, GetTrackingScripts, SaveTrackingScripts, SaveHeadTrackingScripts } from './api/settingsApi'
import { FetchStatusBasedPageConfigs } from './GenericContext'
import { GetSurveyExists } from './api/getSurveyExists'
import { GetPermalinkConfig, GetPermalinkPreview, PostPermalinkConfig } from './api/permalinkApi'
import { GetPageComponentMlMetrics } from './api/getPageComponentMetrics'
import { DuplicateStorefront } from './api/duplicateStorefrontApi'
import { SaveStorePrimitiveSettings } from './api/storePrimitiveSettings'
import { PostLinkToMeta } from './api/postLinkToMeta'
import { DeepRequiredStorePrimitiveSettings, StorePrimitiveSettings } from 'src/views/ComponentSettingsPage/types'
import { GetMetaDownloadAsyncV2 } from './api/getMetaDownloadAsyncV2'

export type PrependShop<T> = T extends (...a: infer Args) => infer Return ? (activeShop: string, ...a: Args) => Return : never
export type PrependCurrency<T> = T extends (...a: infer Args) => infer Return ? (currency: CurrencyCode, ...a: Args) => Return : never;

export type PrependShopCurrency<T> = PrependShop<PrependCurrency<T>>

export interface AuthState {
  isInitialised: boolean
  isAuthenticated: boolean
  user: User
  selectedStore: Store
  stores: string[]
  authError: boolean
}

export type ComponentsSearchFilter = {
  HOOK: boolean
  INTEGRATION: boolean
  SAVED: boolean
}

export interface AuthContextValue extends AuthState {
  method: 'Cognito'
  logout: () => void
  setStore: (store: Store) => void
  refreshStoreList: () => Promise<void>
}

export interface AuthProviderProps {
  children: ReactNode
}

type InitialiseAction = {
  type: 'INITIALISE'
  payload: Pick<AuthState, 'isAuthenticated' | 'user' | 'selectedStore' | 'stores'>
}

type LoginAction = {
  type: 'LOGIN'
  payload: Pick<AuthState, 'user' | 'selectedStore'>
}

type LogoutAction = {
  type: 'LOGOUT'
}

type RegisterAction = {
  type: 'REGISTER'
}

type SetStore = {
  type: 'SET_STORE'
  payload: Pick<AuthState, 'selectedStore'>
}

type SetStores = {
  type: 'SET_STORES'
  payload: Pick<AuthState, 'selectedStore' | 'stores'>
}

type SetAuthError = {
  type: 'AUTHERROR'
}

export interface SocialCampaignElementResponse {
  conversion_rate: number
  revenue: number
  best_source?: string
  previous_revenue?: number
}

export interface SocialChannelElementResponse {
  conversion_rate: number
  revenue: number
  previous_revenue?: number
}

export type SocialChannel = Record<string, SocialChannelElementResponse>

export type SocialCampaigns = Record<string, SocialCampaignElementResponse>

export type Action =
  | InitialiseAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | SetStores
  | SetStore
  | SetAuthError

export type Pagination = {
  limit?: number
  offset?: number
} | undefined

export interface ShopifyContextValue {
  appConnected: boolean
  connectApp: () => Promise<void>
  disconnectApp: () => Promise<void>
  appLoading: boolean
  navMenu: any
  host: string
  shop: string
  publishedProductsCount: number
}

export interface CampaignRevenueGraph {
  timestamp: number
  base_revenue: number
  experience_revenue: number
  experience_base_revenue_pct: number
}

export type Category = {
  id: string
  externalId: string
  title: string
  description: string
  cdpUrl: string
  featuredImage: ImageType[]
  handle: string
}

export type CategoryPrimitive = {
  id: string
  externalId: string
  title: string
  description?: string
  cdpUrl?: string
  featuredImage?: RawImage
  handle: string
}

export type AdsResponse = {
  ads: Ad[]
}

export type PostsResponse = {
  posts: Post[]
}

export type ProductCountResponse = {
  count: number
}

export type Media = {
  mediaType: MediaType
  url: string
  externalId: string
}

export type Ad = {
  externalId: string
  mediaType: MediaType
  // NOTE: when fetchin with getAds urls will be empty
  medias: Media[]
  thumbnail: string

  adName: string
  campaignName: string
  message: string
  startDate: number
  endDate: number

  canWriteUrl: boolean
  links: string[]
  accountId: string
  llmAdDetails: {
    products: string[]
    collections: string[]
    thirdPartyBrands: string[]
    outcome: 'products' | 'collections' | 'thirdPartyBrands' | 'not_clear'
    predictionDescription: string
  }
}

export type MediaType = 'IMAGE' | 'VIDEO' | 'CAROUSEL'

export type Post = {
  externalId: string
  accountId: string
  mediaType: MediaType
  medias: Media[]
  thumbnail: string

  likeCount: number
  commentCount: number
  caption: string
  timestamp: number
}
export enum MerchantPlan {
  None = 'NONE',
  Base = 'BASE',
  Enterprise = 'ENTERPRISE',
}

export type MerchantDetails = {
  shop: string
  status: 'REGISTERED' | 'INSTALLED' | 'INACTIVE'
  plan: MerchantPlan
  platform: 'SHOPIFY'
  createdAt: number
  updatedAt: number
}

// export type ComponentType = {
//   componentId: string
//   containerType: string
//   title?: string
//   contentConfiguration: {
//     type: string
//     value: {
//       uiSettings: {
//         fields: string[]
//       }
//       apiSettings: {
//         productIds?: string[]
//         productHandles?: string[]
//         categoryIds?: string[]
//         categoryHandles?: string[]
//         blacklistedProductIds?: string[]
//         filterBy?: string[]
//         limit?: number
//         offset?: number
//       }
//     }
//   }
// }

export type DynamicPage = {
  layout: string
  componentIds: string[]
}

export type ManualPage = {
  page: PageConfig
}

export type CampaignAnalyticsItem = { campaigns: BarElement[], mediums: BarElement[], sources: BarElement[] }
export type DataStat = { bounceRate: number, conversionRate: number, pageViews: number, aov?: number, campaignRevenue?: number, revenuePerVisit?: number }

export type RefetchComponents = (searchFilter?: ComponentsSearchFilter) => Promise<void>


export type GenericContextValue = {
  homeCustomDate: number[]
  setHomeCustomDate: (val: number[]) => void
  isBrand: boolean
  setIsBrand: (val: boolean) => void
  statusBasedPageConfigs: PageConfig[]
  setStatusBasedPageConfigs: (val: PageConfig[]) => void
  components: ComponentTypeResponse[]
  currencyCode: CurrencyCode
  loadingPages: boolean
  setLoadingPages: (val: boolean) => void
  loadingMore: boolean
  loadingComponents: boolean
  getCurrencyCode: GetCurrencyCode
  publishPageConfig: PublishPageConfig
  utmLinkBuilderPageId: string
  setUtmLinkBuilderPageId: (val: string) => void
  publishComponent: PublishComponent
  getProductsByIds: GetProductsByIds
  getPost: GetPost
  getPosts: GetPosts
  getAd: GetAd
  getAds: GetAds
  postLinkToMeta: PostLinkToMeta
  getCategories: GetCategories
  getCampaignAnalytics: GetCampaignAnalytics
  getCampaignTopChannels: GetCampaignTopChannels
  getTopCampaigns: GetTopCampaigns
  getPerformanceAnalytics: GetPerformanceAnalytics
  duplicateStorefront: DuplicateStorefront
  getLatestOrders: GetLatestOrders
  getTotalRevenue: GetTotalRevenue
  getCampaignRevenueGraph: GetCampaignRevenueGraph
  shop: string
  pageStatusQuery: PageStatus
  setPageStatusQuery: (val: PageStatus) => void
  fetchStatusBasedPageConfigs: FetchStatusBasedPageConfigs
  getPageConfig: GetPageConfig
  saveStoreSettings: SaveStoreSettings
  getStoreSettings: GetStoreSettings
  saveCustomDomain: SaveCustomDomain
  getActiveCustomDomain: GetActiveCustomDomain
  getThirdParty: GetThirdParty
  saveThirdParty: SaveThirdParty
  getTrackingScripts: GetTrackingScripts
  saveTrackingScripts: SaveTrackingScripts
  saveHeadTrackingScripts: SaveHeadTrackingScripts
  getIntegrations: GetIntegrations
  getMetaPixels: GetMetaPixels
  setMetaPixel: SetMetaPixel
  saveCodeGetIntegrations: SaveCodeGetIntegrations
  postInstagramCreator: PostInstagramCreator
  uploadStorefrontImage: UploadStorefrontImage
  getAffectedPages: GetAffectedPages
  getComponents: GetComponents
  refetchComponents: RefetchComponents
  getComponent: GetComponent
  getAllDefaultPages: GetAllDefaultPages
  createDynamicPages: CreateDynamicPages
  searchProducts: SearchProducts
  searchCategories: SearchCategories
  merchantDetails: MerchantDetails
  refreshMerchantDetails: () => Promise<void>
  saveStorePrimitiveSettings: SaveStorePrimitiveSettings
  storePrimitiveSettings: DeepRequiredStorePrimitiveSettings
  fetchStorePrimitiveSettings: () => Promise<StorePrimitiveSettings | undefined>

  getTopCampaigns2: GetTopCampaigns2
  getTopSocialChannels2: GetTopSocialChannels2
  getLatestOrders2: GetLatestOrders2
  getCampaignTickers: GetCampaignTickers
  getCampaignSummaryMetrics: GetCampaignSummaryMetrics
  getCampaignAggregateDayOfWeek: GetCampaignAggregateDayOfWeek
  getMetaDownloadUrl: GetMetaDownloadUrl
  getMetaDownloadUrlV2: GetMetaDownloadUrlV2
  getMetaDownloadAsyncV2: GetMetaDownloadAsyncV2
  getPageComponentMlMetrics: GetPageComponentMlMetrics

  getOptimalPage: GetOptimalPage
  getManualPage: GetManualPage
  getPageMetrics: GetPageMetrics
  getSurveyExists: GetSurveyExists
  getPermalinkConfig: GetPermalinkConfig
  postPermalinkConfig: PostPermalinkConfig
  getPermalinkPreview: GetPermalinkPreview
}

